import axios from 'axios';
import { get } from 'lodash';

import { userServiceUrl } from 'config/environment';

const getCharts = () => {
  return axios
    .get(`${userServiceUrl}/api/chart/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getChart = (id) => {
  return axios
    .get(`${userServiceUrl}/api/chart/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteChart = (id) => {
  return axios
    .delete(`${userServiceUrl}/api/chart/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateChart = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/chart/${id}/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createChart = (data) => {
  return axios
    .post(`${userServiceUrl}/api/chart/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getChartByAttributeId = (id) => {
  return axios
    .get(`${userServiceUrl}/api/chart/attributeDefinitionId/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      // error: {
      //   ...get(err, 'response.data', {}),
      //   message:
      //     get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
      //     'Die Anfrage ist fehlgeschlagen',
      // },
    }));
};

export default {
  getCharts,
  createChart,
  getChart,
  updateChart,
  deleteChart,
  getChartByAttributeId
};
