import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CHARTS = createRequestTypes('GET_CHARTS');
export const GET_CHART = createRequestTypes('GET_CHART');
export const UPDATE_CHART = createRequestTypes('UPDATE_CHART');
export const CREATE_CHART = createRequestTypes('CREATE_CHART');
export const DELETE_CHART = createRequestTypes('DELETE_CHART');
export const GET_CHART_BY_ATTRIBUTE_ID = createRequestTypes('GET_CHART_BY_ATTRIBUTE_ID');

export const getCharts = {
  request: () => action(GET_CHARTS[REQUEST]),
  success: (payload) => action(GET_CHARTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_CHARTS[FAILURE], { payload }),
};

export const getChart = {
  request: (id) => action(GET_CHART[REQUEST], { id }),
  success: (payload) => action(GET_CHART[SUCCESS], { payload }),
  failure: (payload) => action(GET_CHART[FAILURE], { payload }),
};

export const deleteChart = {
  request: (id) => action(DELETE_CHART[REQUEST], { id }),
  success: (payload) => action(DELETE_CHART[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_CHART[FAILURE], { payload }),
};

export const updateChart = {
  request: (id, data, options) => action(UPDATE_CHART[REQUEST], { id, data, options }),
  success: (payload) => action(UPDATE_CHART[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_CHART[FAILURE], { payload }),
};

export const createChart = {
  request: (data, options) => action(CREATE_CHART[REQUEST], { data, options }),
  success: (payload) => action(CREATE_CHART[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_CHART[FAILURE], { payload }),
};

export const getChartByAttributeId = {
  request: (id) => action(GET_CHART_BY_ATTRIBUTE_ID[REQUEST], { id }),
  success: (payload) => action(GET_CHART_BY_ATTRIBUTE_ID[SUCCESS], { payload }),
  failure: (payload) => action(GET_CHART_BY_ATTRIBUTE_ID[FAILURE], { payload }),
};
