import * as ActionTypes from 'actions/Product';
import { get } from 'lodash';

const initialState = {
  products: [],
  product: null,
  productsPage: 0,
  tableFilter: {},
  productConstants: null,
  tableSorting: {},
  selectedProducts: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_LIST.SUCCESS:
      return {
        ...state,
        products: get(action, 'payload.content', []),
        productsPage: get(action, 'payload.currentPage', 0),
        productsPages: get(action, 'payload.totalPages', 0),
      };
    // case ActionTypes.GET_FILTERED_PRODUCT_LIST.SUCCESS:
    //   return {
    //     ...state,
    //     products: get(action, 'payload'),
    //   };
    case ActionTypes.GET_PRODUCT_CONSTANTS.SUCCESS:
      return {
        ...state,
        productConstants: action.payload,
      };
    case ActionTypes.GET_PRODUCT.SUCCESS:
      return {
        ...state,
        product: action.payload,
      };
    case ActionTypes.SET_PRODUCT_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };
    case ActionTypes.SET_PRODUCT_TABLE_SORTING:
      return {
        ...state,
        tableSorting: action.payload,
      };
    case ActionTypes.SELECT_PRODUCT:
      return {
        ...state,
        selectedProducts: action.payload,
      };
    default:
      return state;
  }
}
