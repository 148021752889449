import * as healthProfileActions from 'actions/HealthProfile'
import { all, takeEvery } from 'redux-saga/effects';
import { apiRequest } from './index';
import HealthProfilesApi from '../api/HealthProfiles';
import { Slide, toast } from 'react-toastify';
import history from 'utils/history';

const getHealthProfiles = apiRequest.bind(null, healthProfileActions.getHealthProfiles, HealthProfilesApi.getHealthProfiles);
const getHealthProfile = apiRequest.bind(null, healthProfileActions.getHealthProfile, HealthProfilesApi.getHealthProfile);

export function* rootWatcherHealthProfile() {
  yield all([
    takeEvery(healthProfileActions.GET_HEALTH_PROFILES.REQUEST, getHealthProfiles),
    takeEvery(healthProfileActions.GET_HEALTH_PROFILE.REQUEST, getHealthProfile),
  ]);
}
