import * as ActionTypes from "actions/Attributes";
import {get} from "lodash";

const initialState = {
  attributes: [],
  attribute: {},
  attributeUsers: [],
  userAttributes: [],
  userAttributeHistory: [],
  tableSorting: {},
  tableFilter: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_ATTRIBUTES.SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      };
    case ActionTypes.GET_ATTRIBUTE.SUCCESS:
      return {
        ...state,
        attribute: action.payload,
      };
    case ActionTypes.GET_USER_ATTRIBUTES.SUCCESS:
      return {
        ...state,
        userAttributes: action.payload,
      };
    case ActionTypes.GET_USER_ATTRIBUTE_HISTORY.SUCCESS:
      return {
        ...state,
        userAttributeHistory: action.payload,
      };
    case ActionTypes.GET_ATTRIBUTE_USERS.SUCCESS:
      return {
        ...state,
        attributeUsers: get(action.payload, 'content', []),
        usersPage: get(action, 'payload.currentPage', 0),
        usersPages: get(action, 'payload.totalPages', 0),
      };
    case ActionTypes.SET_ATTRIBUTES_TABLE_FILTER:
      return { ...state, tableFilter: action.payload }
    case ActionTypes.SET_ATTRIBUTES_TABLE_SORTING:
      return { ...state, tableSorting: action.payload }
    default:
      return state;
  }
}
