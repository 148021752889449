import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_USERS = createRequestTypes('GET_USERS');
export const GET_FILTERED_USERS = createRequestTypes('GET_FILTERED_USERS');
export const GET_USER = createRequestTypes('GET_USER');
export const GET_USER_WITH_GROUPS = createRequestTypes('GET_USER_WITH_GROUPS');
export const GET_USER_RESULTS = createRequestTypes('GET_USER_RESULTS');
export const UPDATE_USER = createRequestTypes('UPDATE_USER');
export const CREATE_USER = createRequestTypes('CREATE_USER');
export const DELETE_USER = createRequestTypes('DELETE_USER');
export const DELETE_USERS = createRequestTypes('DELETE_USERS');
export const DOWNLOAD_PDF = createRequestTypes('DOWNLOAD_PDF');
export const UPDATE_USER_PASSWORD = createRequestTypes('UPDATE_USER_PASSWORD');
export const GET_USER_PRODUCTS = createRequestTypes('GET_USER_PRODUCTS');
export const SELECT_USERS = 'SELECT_USERS';
export const SET_USERS_TABLE_FILTER = 'SET_USERS_TABLE_FILTER';
export const SET_USERS_TABLE_SORTING = 'SET_USERS_TABLE_SORTING';

export const getUsers = {
  request: (data) => action(GET_USERS[REQUEST], {data}),
  success: (payload) => action(GET_USERS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USERS[FAILURE], {payload}),
}

export const getUser = {
  request: (id) => action(GET_USER[REQUEST], {id}),
  success: (payload) => action(GET_USER[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER[FAILURE], {payload}),
}

export const getUserWithGroups = {
  request: (id) => action(GET_USER_WITH_GROUPS[REQUEST], {id}),
  success: (payload) => action(GET_USER_WITH_GROUPS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_WITH_GROUPS[FAILURE], {payload}),
}

export const getUserResults = {
  request: (data) => action(GET_USER_RESULTS[REQUEST], {data}),
  success: (payload) => action(GET_USER_RESULTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_RESULTS[FAILURE], {payload}),
}

export const updateUser = {
  request: (data) => action(UPDATE_USER[REQUEST], {data}),
  success: (payload) => action(UPDATE_USER[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_USER[FAILURE], {payload}),
}

export const createUser = {
  request: (data) => action(CREATE_USER[REQUEST], {data}),
  success: (payload) => action(CREATE_USER[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_USER[FAILURE], {payload}),
}

export const updateUserPassword = {
  request: (data) => {
    return action(UPDATE_USER_PASSWORD[REQUEST], { data })
  },
  success: (payload) => action(UPDATE_USER_PASSWORD[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_USER_PASSWORD[FAILURE], {payload}),
}

export const deleteUser = {
  request: (id) => action(DELETE_USER[REQUEST], {id}),
  success: (payload) => action(DELETE_USER[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_USER[FAILURE], {payload}),
}

export const deleteUsers = {
  request: (id) => action(DELETE_USERS[REQUEST], {id}),
  success: (payload) => action(DELETE_USERS[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_USERS[FAILURE], {payload}),
}

export const downloadPdf = {
  request: () => action(DOWNLOAD_PDF[REQUEST]),
  success: (payload) => action(DOWNLOAD_PDF[SUCCESS], {payload}),
  failure: (payload) => action(DOWNLOAD_PDF[FAILURE], {payload}),
}

export const getUserProducts = {
  request: (data) => action(GET_USER_PRODUCTS[REQUEST], {data}),
  success: (payload) => action(GET_USER_PRODUCTS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_PRODUCTS[FAILURE], {payload}),
}

export const selectUsers = (data) => ({
  type: SELECT_USERS,
  payload: data
})

export const setUsersTableFilter = (payload) => ({
  type: SET_USERS_TABLE_FILTER,
  payload
})

export const setUsersTableSorting = (payload) => ({
  type: SET_USERS_TABLE_SORTING,
  payload
})