import { put, takeEvery } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import * as authActions from "actions/Auth";
import * as surveyActions from "actions/Survey";
import { get } from "lodash";
import jwtDecode from "jwt-decode";
import history from "utils/history";
import { checkTokenExpiration, checkRefreshTokenExpiration } from "utils/token";
import generateFileURL from 'utils/generateFileURL';
import Axios from 'axios';

const loginUser = apiRequest.bind(null, authActions.login, api.login);
const refreshToken = apiRequest.bind(
  null,
  authActions.getNewToken,
  api.refreshToken
);
const requestPasswordReset = apiRequest.bind(
  null,
  authActions.requestPasswordReset,
  api.requestPasswordReset
);
const resetPassword = apiRequest.bind(
  null,
  authActions.resetPassword,
  api.resetPassword
);

//TODO: implement multiple role
const getUserRole = decoded =>
  get(decoded, "realm_access.roles", []).reduce((acc, el) => {
    if (el === "admin" || acc === "admin") return "admin";
    if (el === "tenant-admin" || acc === "tenant-admin") return "tenant-admin";
    if (el === "editor" || acc === "editor") return "editor";
    if (el === "reviewer" || acc === "reviewer") return "reviewer";
    if (el === "approver" || acc === "approver") return "approver";
    return acc;
  }, "");

function* getToken() {
  const token = checkTokenExpiration();
  if (token && token !== "expired") {
    const decoded = jwtDecode(token);
    console.log(decoded)

    const userRole = getUserRole(decoded);
    yield put({ type: "SET_USER_ROLE", payload: userRole });
    yield put({ type: authActions.GET_TOKEN.SUCCESS, payload: decoded });
  } else {
    if (token === "expired") {
      const refreshToken = checkRefreshTokenExpiration();
      if (!refreshToken || refreshToken === "expired") {
        yield put({
          type: authActions.GET_TOKEN.FAILURE,
          payload: { error: { message: "Session expired" } }
        });
      } else {
        yield put({
          type: authActions.GET_NEW_TOKEN.REQUEST,
          data: refreshToken
        });
      }
    } else yield put({ type: authActions.GET_TOKEN.FAILURE });
  }
}

function* saveToken(action) {
  const { access_token, refresh_token, decoded, isRefresh } = action.payload;
  const userRole = getUserRole(decoded);
  window.localStorage.setItem("IQ_TOKEN", access_token);
  window.localStorage.setItem("IQ_REFRESH_TOKEN", refresh_token);
  yield put({
    type: "SET_USER_ROLE",
    payload: userRole,
    isRedirect: isRefresh ? false : true
  });
  yield put({ type: authActions.GET_TOKEN.SUCCESS, payload: decoded });
}

function* removeToken() {
  window.localStorage.removeItem("IQ_TOKEN");
  window.localStorage.removeItem("IQ_REFRESH_TOKEN");
  // yield put ({ type: licenseeActions.USERS.SUCCESS, payload: [] }); // clear users
  yield put({ type: "SET_USER_ROLE", payload: "" });
  yield put({ type: authActions.LOGOUT.SUCCESS });
  if (window.noLoginRedirect) {
    window.noLoginRedirect = false;
  } else {
    history.push("/login");
  }
}

export function* handleInvalidToken() {
  yield put({ type: authActions.LOGOUT.REQUEST });
  yield put({
    type: authActions.GET_TOKEN.FAILURE,
    payload: { error: { message: "Session expired" } }
  });
}

function* handleSetUserRole(action) {
  if (action.isRedirect) yield history.push("/");
}

function* handlePasswordReset() {
  yield history.push("/login");
}

function* getFileViaToken({ data, fileName }) {
  if (!data) return;
  const a = document.createElement('a');
  yield Axios.get(data, {
    responseType: 'arraybuffer',
  }).then((response) => {
    const url = generateFileURL(response);
    a.download = fileName;
    a.href = url;
    a.click();
  });
  yield put({ type: authActions.GET_FILE_VIA_TOKEN.SUCCESS });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetToken() {
  yield takeEvery(authActions.GET_TOKEN.REQUEST, getToken);
}

export function* watchLoginUser() {
  yield takeEvery(authActions.LOGIN.REQUEST, loginUser);
}

export function* watchLoginSuccess() {
  yield takeEvery(authActions.LOGIN.SUCCESS, saveToken);
}

export function* watchLogout() {
  yield takeEvery(authActions.LOGOUT.REQUEST, removeToken);
}

export function* watchGetTokenFailure() {
  yield takeEvery(authActions.GET_TOKEN.FAILURE, removeToken);
}

export function* watchRefreshToken() {
  yield takeEvery(authActions.GET_NEW_TOKEN.REQUEST, refreshToken);
}

export function* watchRefreshTokenSuccess() {
  yield takeEvery(authActions.GET_NEW_TOKEN.SUCCESS, saveToken);
}

export function* watchRefreshTokenFailure() {
  yield takeEvery(authActions.GET_NEW_TOKEN.FAILURE, handleInvalidToken);
}

export function* watchSetUserRole() {
  yield takeEvery("SET_USER_ROLE", handleSetUserRole);
}

export function* watchRequestPasswordResetRequest() {
  yield takeEvery(
    authActions.REQUEST_PASSWORD_RESET.REQUEST,
    requestPasswordReset
  );
}

export function* watchResetPasswordRequest() {
  yield takeEvery(authActions.RESET_PASSWORD.REQUEST, resetPassword);
}

export function* watchResetPasswordSuccess() {
  yield takeEvery(authActions.RESET_PASSWORD.SUCCESS, handlePasswordReset);
}

export function* watchGetFileViaTokenRequest() {
  yield takeEvery(authActions.GET_FILE_VIA_TOKEN.REQUEST, getFileViaToken);
}


