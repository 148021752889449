import { put, takeEvery, select } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as userActions from 'actions/User';
import { get } from 'lodash';
import history from 'utils/history';
import { Slide, toast } from "react-toastify";

const getUsers = apiRequest.bind(null, userActions.getUsers, api.getUsers);
const getUser = apiRequest.bind(null, userActions.getUser, api.getUser);
const createUser = apiRequest.bind(null, userActions.createUser, api.createUser);
const getUserWithGroups = apiRequest.bind(null, userActions.getUserWithGroups, api.getUserWithGroups);
const updateUserPassword = apiRequest.bind(null, userActions.updateUserPassword, api.updateUserPassword);
const getUserProducts = apiRequest.bind(null, userActions.getUserProducts, api.getUserProducts);
const updateUser = apiRequest.bind(
  null,
  userActions.updateUser,
  api.updateUser
);
const deleteUser = apiRequest.bind(
  null,
  userActions.deleteUser,
  api.deleteUser
);
const getUserResults = apiRequest.bind(
  null,
  userActions.getUserResults,
  api.getUsers
);
const deleteUsers = apiRequest.bind(
  null,
  userActions.deleteUsers,
  api.deleteUsers
);

function* refreshUsers() {
  const state = yield select();
  const arg = {
    ...get(arguments, "0", {}),
    data: {
      page: get(state, "User.usersPage", 0)
    }
  };
  yield apiRequest.apply(null, [userActions.getUsers, api.getUsers, arg]);
}

function* handleUpdateSuccess() {
  toast('Erfolgreich geändert!', {
    transition: Slide,
    closeButton: true,
    position: "bottom-center",
    autoClose: 2000,
    type: 'success',
  })
}

function* handleUpdateUser(action) {
  if (action.payload.options === "redirect") {
    return  yield history.push("/users");
  } else {
    yield history.push(`/edit-user/${action.payload.id}`);

    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type: 'success'
    })
  }
}

function* handleDeleteMutlipleUsers() {
  yield refreshUsers();
  yield put({ type: userActions.SELECT_USERS, payload: {} });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetUsersRequest() {
  yield takeEvery(userActions.GET_USERS.REQUEST, getUsers);
}

export function* watchGetUserRequest() {
  yield takeEvery(userActions.GET_USER.REQUEST, getUser);
}

export function* watchGetUserWithGroupsRequest() {
  yield takeEvery(userActions.GET_USER_WITH_GROUPS.REQUEST, getUserWithGroups);
}

export function* watchUpdateUserPasswordRequest() {
  yield takeEvery(userActions.UPDATE_USER_PASSWORD.REQUEST, updateUserPassword);
}

export function* watchUpdateUserPasswordSuccess() {
  yield takeEvery(userActions.UPDATE_USER_PASSWORD.SUCCESS, handleUpdateSuccess);
}

export function* watchUpdateUserRequest() {
  yield takeEvery(userActions.UPDATE_USER.REQUEST, updateUser);
}

export function* watchCreateUserRequest() {
  yield takeEvery(userActions.CREATE_USER.REQUEST, createUser);
}

export function* watchCreateUserSuccess() {
  yield takeEvery(userActions.CREATE_USER.SUCCESS, handleUpdateUser);
}

export function* watchUpdateUserSuccess() {
  yield takeEvery(userActions.UPDATE_USER.SUCCESS, handleUpdateUser);
}

export function* watchDeleteUserRequest() {
  yield takeEvery(userActions.DELETE_USER.REQUEST, deleteUser);
}

export function* watchDeleteUserSuccess() {
  yield takeEvery(userActions.DELETE_USER.SUCCESS, refreshUsers);
}

export function* watchDeleteUsersRequest() {
  yield takeEvery(userActions.DELETE_USERS.REQUEST, deleteUsers);
}

export function* watchDeleteUsersSuccess() {
  yield takeEvery(userActions.DELETE_USERS.SUCCESS, handleDeleteMutlipleUsers);
}

export function* watchGetUserResultsRequest() {
  yield takeEvery(userActions.GET_USER_RESULTS.REQUEST, getUserResults);
}

export function* watchGetUserProductsRequest() {
  yield takeEvery(userActions.GET_USER_PRODUCTS.REQUEST, getUserProducts);
}
