import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_APPOINTMENTS = createRequestTypes('GET_APPOINTMENTS');
export const CREATE_APPOINTMENT = createRequestTypes('CREATE_APPOINTMENT');
export const GET_APPOINTMENT = createRequestTypes('GET_APPOINTMENT');
export const UPDATE_APPOINTMENT = createRequestTypes('UPDATE_APPOINTMENT');
export const DELETE_APPOINTMENT = createRequestTypes('DELETE_APPOINTMENT');

export const getAppointments = {
  request: () => action(GET_APPOINTMENTS[REQUEST]),
  success: (payload) => action(GET_APPOINTMENTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_APPOINTMENTS[FAILURE], { payload }),
};

export const createAppointment = {
  request: (data, options) => action(CREATE_APPOINTMENT[REQUEST], { data, options }),
  success: (payload) => action(CREATE_APPOINTMENT[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_APPOINTMENT[FAILURE], { payload }),
};

export const getAppointment = {
  request: (id) => action(GET_APPOINTMENT[REQUEST], { id }),
  success: (payload) => action(GET_APPOINTMENT[SUCCESS], { payload }),
  failure: (payload) => action(GET_APPOINTMENT[FAILURE], { payload }),
};

export const updateAppointment = {
  request: (id, data, options) => action(UPDATE_APPOINTMENT[REQUEST], { id, data, options }),
  success: (payload) => action(UPDATE_APPOINTMENT[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_APPOINTMENT[FAILURE], { payload }),
};

export const deleteAppointment = {
  request: (id) => action(DELETE_APPOINTMENT[REQUEST], { id }),
  success: (payload) => action(DELETE_APPOINTMENT[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_APPOINTMENT[FAILURE], { payload }),
};
