import * as healthAssistantActions from 'actions/HealthAssistant'
import { all, takeEvery } from 'redux-saga/effects';
import { apiRequest } from './index';
import HealthAssistantsApi from '../api/HealthAssistant';
import { Slide, toast } from 'react-toastify';
import history from 'utils/history';

const getHealthAssistants = apiRequest.bind(null, healthAssistantActions.getHealthAssistants, HealthAssistantsApi.getHealthAssistants);
const getHealthAssistant = apiRequest.bind(null, healthAssistantActions.getHealthAssistant, HealthAssistantsApi.getHealthAssistant);

export function* rootWatcherHealthAssistant() {
  yield all([
    takeEvery(healthAssistantActions.GET_HEALTH_ASSISTANTS.REQUEST, getHealthAssistants),
    takeEvery(healthAssistantActions.GET_HEALTH_ASSISTANT.REQUEST, getHealthAssistant),
  ]);
}
