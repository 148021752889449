import axios from 'axios';
import { get, omit } from 'lodash';
import { cubeServiceUrl } from 'config/environment';

const getHealthAssistants = () => {
  return axios
    .get(`${cubeServiceUrl}/api/healthAssistant/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
}

const getHealthAssistant = (id) => {
  return axios
    .get(`${cubeServiceUrl}/api/healthAssistant/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
}

const updateHealthAssistant = (data) => {
  return axios
    .put(`${cubeServiceUrl}/api/healthAssistant/${data.id}/v1.0/`, omit(data, 'id'))
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
}

export default {
  getHealthAssistants,
  getHealthAssistant,
  updateHealthAssistant,
}
