import { put, takeEvery } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import * as surveyActions from "actions/Survey";
import * as userActions from "../actions/User";
import { Slide, toast } from "react-toastify";
import { get } from "lodash";
import history from "../utils/history";
import * as tagsActions from "../actions/Tags";

const getSurveys = apiRequest.bind(
  null,
  surveyActions.getSurveys,
  api.getSurveys
);

const getSurvey = apiRequest.bind(
  null,
  surveyActions.getSurvey,
  api.getSurvey
);

const createSurvey = apiRequest.bind(
  null,
  surveyActions.createSurvey,
  api.createSurvey
);

const updateSurvey = apiRequest.bind(
  null,
  surveyActions.updateSurvey,
  api.updateSurvey
);

const deleteSurvey = apiRequest.bind(
  null,
  surveyActions.deleteSurvey,
  api.deleteSurvey
);

const sendDirectSurvey = apiRequest.bind(
  null,
  surveyActions.sendDirectSurvey,
  api.sendDirectSurvey
);

function* handleUpdateSuccess(action) {
  if (get(action.payload, 'options') === "redirect") {
    return yield history.push("/surveys");
  } else {
    if (history.location.pathname.includes('create')) {
      yield history.push(`/edit-survey/${action.payload.id}`)
      const arg = {id: action.payload.name}
      yield apiRequest.apply(null, [surveyActions.getSurvey, api.getSurvey, arg])
    }
    yield showSuccessMessage()
  }
}

function* showSuccessMessage() {
  yield toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: "bottom-center",
    type: 'success'
  })
}

function* updateList() {
  yield put({type: surveyActions.GET_SURVEYS.REQUEST});

  yield showSuccessMessage();
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetSurveysRequest() {
  yield takeEvery(surveyActions.GET_SURVEYS.REQUEST, getSurveys);
}

export function* watchGetSurveyRequest() {
  yield takeEvery(surveyActions.GET_SURVEY.REQUEST, getSurvey);
}

export function* watchCreateSurveyRequest() {
  yield takeEvery(surveyActions.CREATE_SURVEY.REQUEST, createSurvey);
}

export function* watchCreateSurveySuccess() {
  yield takeEvery(surveyActions.CREATE_SURVEY.SUCCESS, handleUpdateSuccess);
}


export function* watchUpdateSurveyRequest() {
  yield takeEvery(surveyActions.UPDATE_SURVEY.REQUEST, updateSurvey);
}

export function* watchUpdateSurveySuccess() {
  yield takeEvery(surveyActions.UPDATE_SURVEY.SUCCESS, handleUpdateSuccess);
}

export function* watchDeleteSurveyRequest() {
  yield takeEvery(surveyActions.DELETE_SURVEY.REQUEST, deleteSurvey);
}

export function* watchDeleteSurveySuccess() {
  yield takeEvery(surveyActions.DELETE_SURVEY.SUCCESS, updateList);
}

export function* watchSendDirectSurveyRequest() {
  yield takeEvery(surveyActions.SEND_DIRECT_SURVEY.REQUEST, sendDirectSurvey);
}

export function* watchSendDirectSurveySuccess() {
  yield takeEvery(surveyActions.SEND_DIRECT_SURVEY.SUCCESS, showSuccessMessage);
}