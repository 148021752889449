import { all, takeEvery, put } from 'redux-saga/effects';
import { apiRequest } from './index';
import ChartsApi from '../api/Charts';
import * as chartsActions from 'actions/Charts';
import * as userActions from 'actions/User';
import history from 'utils/history';
import { get } from 'lodash';
import { Slide, toast } from 'react-toastify';

const getCharts = apiRequest.bind(null, chartsActions.getCharts, ChartsApi.getCharts);

const getChart = apiRequest.bind(null, chartsActions.getChart, ChartsApi.getChart);

const deleteChart = apiRequest.bind(null, chartsActions.deleteChart, ChartsApi.deleteChart);

const getChartByAttributeId = apiRequest.bind(
  null,
  chartsActions.getChartByAttributeId,
  ChartsApi.getChartByAttributeId
);

function* updateChart({ id, data, options }) {
  const { response, error } = yield ChartsApi.updateChart(id, data);
  if (error) {
    return yield put({
      type: chartsActions.UPDATE_CHART.FAILURE,
      payload: response,
    });
  }
  yield put({
    type: chartsActions.UPDATE_CHART.SUCCESS,
    payload: response,
  });

  if (options === 'redirect') {
    yield history.push('/charts');
  }
}

function* createChart({ data, options }) {
  const { response, error } = yield ChartsApi.createChart(data);
  if (error) {
    return yield put({
      type: chartsActions.CREATE_CHART.FAILURE,
      payload: response,
    });
  }
  yield put({
    type: chartsActions.CREATE_CHART.SUCCESS,
    payload: response,
  });

  if (options === 'redirect') {
    yield history.push('/charts');
  } else {
    yield history.replace(`/update-chart/${response.id}`);
  }
}

const chartSaveHandler = () => {
  toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: 'bottom-center',
    type: 'success',
  });
};

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* rootWatcherChartsRequest() {
  yield all([
    takeEvery(chartsActions.GET_CHARTS.REQUEST, getCharts),
    takeEvery(chartsActions.GET_CHART.REQUEST, getChart),
    takeEvery(chartsActions.UPDATE_CHART.REQUEST, updateChart),
    takeEvery(chartsActions.UPDATE_CHART.SUCCESS, chartSaveHandler),
    takeEvery(chartsActions.CREATE_CHART.REQUEST, createChart),
    takeEvery(chartsActions.CREATE_CHART.SUCCESS, chartSaveHandler),
    takeEvery(chartsActions.DELETE_CHART.REQUEST, deleteChart),
    takeEvery(chartsActions.GET_CHART_BY_ATTRIBUTE_ID.REQUEST, getChartByAttributeId),
  ]);
}
