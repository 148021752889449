export const NavItems = [
  {
    label: 'Arbeitskorb',
    to: '/workflow',
    icon: 'pe-7s-news-paper',
  },
  {
    label: 'Nachrichten',
    to: '/news',
    icon: 'pe-7s-news-paper',
    disableForRoles: ['reviewer'],
  },
  {
    label: 'Kampagnen',
    to: '/campaigns',
    icon: 'pe-7s-news-paper',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin'],
  },
  {
    label: 'Mandanten',
    to: '/tenants',
    icon: 'pe-7s-users',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin'],
  },
  {
    label: 'Kundenmanagement',
    to: '/users',
    icon: 'pe-7s-user',
    disableForRoles: ['editor', 'reviewer', 'approver'],
  },
  {
    label: 'Kategorien',
    to: '/categories',
    icon: 'pe-7s-ribbon',
    disableForRoles: ['editor', 'reviewer', 'approver'],
  },
  {
    label: 'Produkte',
    to: '/products',
    icon: 'pe-7s-shopbag',
    disableForRoles: ['editor', 'reviewer', 'approver'],
  },
  {
    label: 'Surveys',
    to: '/surveys',
    icon: 'pe-7s-phone',
    disableForRoles: ['editor', 'reviewer', 'approver'],
  },
  {
    label: 'Tags',
    to: '/tags',
    icon: 'pe-7s-ribbon',
    disableForRoles: ['editor', 'reviewer', 'approver'],
  },
  {
    label: 'Attributes',
    to: '/attributes',
    icon: 'pe-7s-users',
    disableForRoles: ['editor', 'reviewer', 'approver'],
  },
  {
    label: 'Charts',
    to: '/charts',
    icon: 'pe-7s-graph2',
    disableForRoles: ['editor', 'reviewer', 'approver'],
  },
  {
    label: 'Termin Template',
    to: '/appointments',
    icon: 'pe-7s-date',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin'],
  },
  {
    label: 'Cubes',
    to: '/cubes',
    icon: 'pe-7s-box2',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin'],
  },
  {
    label: '8 Elements Health Profile',
    to: '/health-profiles',
    icon: 'pe-7s-id',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin'],
  },
  {
    label: 'Health Assistants',
    to: '/health-assistants',
    icon: 'pe-7s-bandaid',
    disableForRoles: ['editor', 'reviewer', 'approver', 'tenant-admin'],
  }
];

const getNav = (userRole) => {
  return NavItems.filter((el) => {
    return !(el.disableForRoles && el.disableForRoles.indexOf(userRole) !== -1);
  });
};

export const navItemsByRole = {
  admin: getNav('admin'),
  'tenant-admin': getNav('tenant-admin'),
  reviewer: getNav('reviewer'),
  editor: getNav('editor'),
  approver: getNav('approver'),
};
