import { takeEvery, select } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import * as groupsActions from "actions/Groups";
import history from "utils/history";
import * as userActions from 'actions/User';
import {get} from "lodash";
import * as categoriesActions from "../actions/Categories";
import {Slide, toast} from "react-toastify";

const getGroups = apiRequest.bind(null, groupsActions.getGroups, api.getGroups);
const addUsersToGroup = apiRequest.bind(null, groupsActions.addUsersToGroup, api.addUsersToGroup);
const deleteUsersFromGroup = apiRequest.bind(null, groupsActions.deleteUsersFromGroup, api.deleteUsersFromGroup);
const getGroupUsers = apiRequest.bind(null, groupsActions.getGroupUsers, api.getUsers);
// const getUserGroups = apiRequest.bind(null, groupsActions.getUserGroups, api.getUserGroups);
const getGroup = apiRequest.bind(null, groupsActions.getGroup, api.getGroup);
const createGroup = apiRequest.bind(
  null,
  groupsActions.createGroup,
  api.createGroup
);
const updateGroup = apiRequest.bind(
  null,
  groupsActions.updateGroup,
  api.updateGroup
);
const deleteGroup = apiRequest.bind(
  null,
  groupsActions.deleteGroup,
  api.deleteGroup
);

function* handleChangeGroupSuccess(action) {
  if (get(action.payload, 'options') === "redirect") {
    return yield history.push("/groups");
  } else {
    yield history.push(`/edit-group/${action.payload.id}`)
    const arg = {id: action.payload.id}
    yield apiRequest.apply(null, [groupsActions.getGroup, api.getGroup, arg])

    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type: 'success'
    })
  }
}

function* handleUserGroupsRefresh() {
  const getUserUuid = (state) => state.User.user.userUuid;
  const userUuid = yield select(getUserUuid);

  const arg = { id: userUuid }

  yield apiRequest.apply(null, [ groupsActions.getUserGroups, api.getUserGroups, arg ])
}

function* handleGroupUsersRefresh() {
  const getGroupId = (state) => state.Groups.group.id;
  const groupId = yield select(getGroupId);

  const arg = { data: { groupId } }

  yield apiRequest.apply(null, [ groupsActions.getGroupUsers, api.getUsers, arg ])
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetGroupsRequest() {
  yield takeEvery(groupsActions.GET_GROUPS.REQUEST, getGroups);
}

export function* watchGetGroupRequest() {
  yield takeEvery(groupsActions.GET_GROUP.REQUEST, getGroup);
}

export function* watchCreateGroupRequest() {
  yield takeEvery(groupsActions.CREATE_GROUP.REQUEST, createGroup);
}

export function* watchUpdateGroupRequest() {
  yield takeEvery(groupsActions.UPDATE_GROUP.REQUEST, updateGroup);
}

export function* watchDeleteGroupRequest() {
  yield takeEvery(groupsActions.DELETE_GROUP.REQUEST, deleteGroup);
}

export function* watchDeleteGroupSuccess() {
  yield takeEvery(groupsActions.DELETE_GROUP.SUCCESS, getGroups);
}

export function* watchCreateGroupSuccess() {
  yield takeEvery(groupsActions.CREATE_GROUP.SUCCESS, handleChangeGroupSuccess);
}

export function* watchUpdateGroupSuccess() {
  yield takeEvery(groupsActions.UPDATE_GROUP.SUCCESS, handleChangeGroupSuccess);
}

export function* watchAddUsersToGroupRequest() {
  yield takeEvery(groupsActions.ADD_USERS_TO_GROUP.REQUEST, addUsersToGroup)
}

export function* watchAddUsersToGroupSuccess() {
  yield takeEvery(groupsActions.ADD_USERS_TO_GROUP.SUCCESS, handleGroupUsersRefresh)
}

export function* watchDeleteUsersFromGroupRequest() {
  yield takeEvery(groupsActions.DELETE_USERS_FROM_GROUP.REQUEST, deleteUsersFromGroup)
}

export function* watchDeleteUsersFromGroupSuccess() {
  yield takeEvery(groupsActions.DELETE_USERS_FROM_GROUP.SUCCESS, handleGroupUsersRefresh)
}

export function* watchGetGroupUsersRequest() {
  yield takeEvery(groupsActions.GET_GROUP_USERS.REQUEST, getGroupUsers)
}
//
// export function* watchGetUserGroupsRequest() {
//   yield takeEvery(groupsActions.GET_USER_GROUPS.REQUEST, getUserGroups)
// }

