import axios from 'axios';
import querystring from 'querystring';
import jwtDecode from 'jwt-decode';
import { get, omit } from 'lodash';
import history from 'utils/history';
import {
  checkTokenExpiration,
  checkRefreshTokenExpiration,
  saveToken,
  saveRefreshToken,
  clearTokens,
} from 'utils/token';

import {
  baseUrl,
  userServiceUrl,
  medicineServiceUrl,
  medicineServiceVersion,
  keycloakUrl,
  realm,
  clientId,
  dmsUrl,
  surveyServiceUrl,
  calendarServiceUrl,
  campaignServiceUrl,
} from 'config/environment';

import { flatDataToTreeNew } from 'utils/documentTreeHelper';

const dmsApiVersion = 'v1.0';

axios.interceptors.request.use(
  async (config) => {
    // config.onUploadProgress = function(progressEvent) {
    //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    //   store.dispatch(setProgress(percentCompleted));
    // };

    // config.headers['Accept-Language'] = 'en-US;';

    if (
      config.url.includes(keycloakUrl) ||
      config.url.includes('tenants/registration/code') ||
      config.url.includes('update-password-with-check') ||
      config.url.includes('send-mail/forgot')
    )
      return Promise.resolve(config);

    const token = checkTokenExpiration();
    if (token && token !== 'expired') {
      config.headers['Authorization'] = 'Bearer ' + token;
      config.withCredentials = true;
    } else if (token && token === 'expired') {
      const refreshToken1 = checkRefreshTokenExpiration();
      if (refreshToken1 && refreshToken1 !== 'expired') {
        const { error, response } = await refreshToken(refreshToken1);
        if (!error) {
          saveToken(response.access_token);
          saveRefreshToken(response.refresh_token);

          config.headers['Authorization'] = 'Bearer ' + response.access_token;
          config.withCredentials = true;
        } else {
          clearTokens();

          if (!history.location.pathname.includes('forgot-password')) {
            history.push('/login');
          }
        }
      } else {
        clearTokens();
        if (!history.location.pathname.includes('forgot-password')) {
          history.push('/login');
        }
      }
    } else if (!token) {
      clearTokens();
      if (!history.location.pathname.includes('forgot-password')) {
        history.push('/login');
      }
    }
    return Promise.resolve(config);
  },
  (error) => {
    Promise.reject(error);
  }
);

const login = ({ username, password, code }) => {
  return axios
    .get(`${dmsUrl}/api/tenants/registration/code/${code}/v1.0/`)
    .then((response) => {
      const payload = querystring.stringify({
        client_id: clientId,
        username: username,
        password: password,
        grant_type: 'password',
      });
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      return axios.post(
        `${keycloakUrl}/auth/realms/${response.data}/protocol/openid-connect/token`,
        payload,
        config
      );
    })
    .then((response) => {
      return {
        response: {
          ...get(response, 'data', {}),
          decoded: jwtDecode(get(response, 'data.access_token')),
        },
      };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die E-Mail oder das Passwort ist nicht korrekt.',
      },
    }));
};

const requestPasswordReset = (email, code) => {
  return axios
    .get(`${dmsUrl}/api/tenants/registration/code/${code}/v1.0/`)
    .then((response) => {
      return axios
        .put(
          `${userServiceUrl}/api/users/forgot-password/v1.0/?email=${email}&tenant=${response.data}`
        )
        .then((response) => ({ response: response.data }))
        .catch((err) => ({
          error: {
            ...get(err, 'response.data', {}),
            message: 'Kein Benutzer mit dieser E-Mail Adresse',
          },
        }));
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Kein Benutzer mit diesem Code',
      },
    }));
};

const resetPassword = (data) => {
  return axios
    .get(`${baseUrl}/api/wizard/correlationId/${data.correlationId}`)
    .then((response) => {
      return axios
        .put(`${userServiceUrl}/api/users/update-password`, {
          correlationId: data.correlationId,
          password: data.password,
          principal: get(response, 'data.principal'),
        })
        .then((response) => response)
        .catch((err) => ({
          error: {
            ...get(err, 'response.data', {}),
            message: 'Die Anfrage ist fehlgeschlagen',
          },
        }));
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const refreshToken = (token) => {
  const payload = querystring.stringify({
    client_id: clientId,
    refresh_token: token,
    grant_type: 'refresh_token',
  });
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .post(
      `${keycloakUrl}/auth/realms/${realm}/protocol/openid-connect/token`,
      payload,
      config
    )
    .then((response) => {
      return {
        response: {
          ...get(response, 'data', {}),
          decoded: jwtDecode(get(response, 'data.access_token')),
          isRefresh: true,
        },
      };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Das Session ist abgelaufen',
      },
    }));
};

const getUsers = (data = {}) => {
  let requestUrl = `${userServiceUrl}/api/users/filter/${dmsApiVersion}/?size=${
    data.size || 100
  }&page=${data.page || 0}`;
  if (data.groupId) {
    requestUrl += `&groupId=${get(data, 'groupId')}`;
  }
  if (data.userContains && data.userContains.length)
    requestUrl += `&userContains=${get(data, 'userContains')}`;
  if (data.sortBy)
    requestUrl += `&sort.${data.sortBy}.direction=${get(
      data,
      'sortDirection',
      ''
    )}&sort.${data.sortBy}.order=0`;
  return axios
    .get(requestUrl)
    .then((response) => {
      return {
        response: typeof response.data === 'string' ? [] : response.data,
      };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich die User zu bekommen',
      },
    }));
};

const getUser = (id) => {
  return axios
    .get(`${userServiceUrl}/api/users/${id}/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich den Benutzer zu bekommen',
      },
    }));
};

const getUserWithGroups = (id) => {
  return axios
    .get(`${userServiceUrl}/api/users/${id}/with-groups/${dmsApiVersion}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich den Benutzer zu bekommen',
      },
    }));
};

const updateUser = (data) => {
  return axios
    .patch(
      `${userServiceUrl}/api/users/${data.id}/update-user-by-admin/v1.0/`,
      omit(data, 'options')
    )
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich den Benutzer zu ändern',
      },
    }));
};

const createUser = (data) => {
  return axios
    .post(`${userServiceUrl}/api/users/v1.1/`, omit(data, 'options'))
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich den Benutzer zu erstellen',
      },
    }));
};

const deleteUser = (id) => {
  return axios
    .delete(`${userServiceUrl}/api/users/${id}/sensitive-data/v1.0/`)
    .then((response) => ({ response: response.data || 'success' }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich den Benutzer zu entfernen',
      },
    }));
};

const getUserProducts = (data) => {
  let requestUrl = `${medicineServiceUrl}/api/user-product/user/${data.userUuid}/v1.0/`;
  if (data.status) requestUrl += `?statuses=${get(data, 'status', '')}`;
  return axios
    .get(requestUrl)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteUsers = async (data) => {
  const res = await data.reduce(async (acc, id) => {
    const { response, error } = await deleteUser(id);
    if (error)
      return {
        error: {
          ...get(error, 'response.data', {}),
          message: 'Nicht möglich den Benutzer zu entfernen',
        },
      };
    if (response) return { response: response.data || 'success' };
  }, {});
  return res;
};

const updateUserPassword = (data) => {
  return axios
    .put(
      `${userServiceUrl}/api/users/${data.id}/update-password-by-admin/v1.0/`,
      data.passwordPayload
    )
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich den Passwort zu ändern',
      },
    }));
};

const getWizardByCorrelationId = (id) => {
  return axios
    .get(`${baseUrl}/api/wizard/correlationId/${id}`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nicht möglich den Benutzer zu bekommen',
      },
    }));
};

const checkRegistrationStatus = (id) => {
  return axios
    .get(`${baseUrl}/api/wizard/${id}/status`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAlcoNorms = () => {
  return axios
    .get(`${baseUrl}/api/intervention/alcohol-norms`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to load Alcohol norms',
      },
    }));
};

const getGoals = () => {
  return axios
    .get(`${baseUrl}/api/goal`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to load goals',
      },
    }));
};

const saveSimulation = (id, data) => {
  return axios
    .post(`${baseUrl}/api/intervention/${id}`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to save simulation',
      },
    }));
};

const simulateSurvey = (data) => {
  return axios
    .post(`${baseUrl}/api/mce`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to simulate survey',
      },
    }));
};

const updateWizard = (id, data) => {
  return axios
    .put(`${baseUrl}/api/wizard/${id}`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to simulate survey',
      },
    }));
};

const getDocuments = (token) => {
  return axios
    .get(`${dmsUrl}/api/article/all/current`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => {
      // const sorted = sortBy(response.data, el => el.path.length);
      const documentsTree = flatDataToTreeNew(response.data);

      const challengesFolder = documentsTree.find(
        (el) => el.name === 'Challenges'
      );
      const pubChallengesFolder = documentsTree.find(
        (el) => el.name === 'Public Challenges'
      );
      const pubWikiFolder = documentsTree.find(
        (el) => el.name === 'Public Wiki'
      );
      const wikiFolder = documentsTree.find((el) => el.name === 'Wiki');
      const simFolder = documentsTree.find((el) => el.name === 'Simulation');
      const pubPagesFolder = get(
        documentsTree.find((el) => el.name === 'Public Pages'),
        'children',
        []
      );
      const vitalMonitorFolder = documentsTree.find(
        (el) => el.name === 'Vital Monitor'
      );
      const targetChallengesFolder = documentsTree.find(
        (el) => el.name === 'Target Challenges'
      );

      return {
        response: {
          list: response.data,
          tree: documentsTree,
          challenges: challengesFolder,
          publicChallenges: pubChallengesFolder,
          publicPages: pubPagesFolder,
          publicWiki: pubWikiFolder,
          simulationDocs: simFolder,
          vitalMonitorDocs: vitalMonitorFolder,
          targetChallenges: targetChallengesFolder,
          wiki: wikiFolder,
        },
      };
    })
    .catch((err) => {
      console.log('documents error', err);
      return {
        error: {
          ...get(err, 'response.data', {}),
          message: 'Die Anfrage ist fehlgeschlagen',
        },
      };
    });
};

const getDocument = (id, token) => {
  return axios
    .get(`${dmsUrl}/api/article/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getDocumentByVersion = (data, token) => {
  return axios
    .get(`${dmsUrl}/api/article/version/${data.id}/${data.versionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getDocumentPermissions = (id) => {
  return axios
    .get(`${dmsUrl}/api/article/permissions/${id}/grouped-by-type/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to get document permissions',
      },
    }));
};

const createDocumentPermissions = (id, data) => {
  return axios
    .post(`${dmsUrl}/api/article/permissions/${id}/v1.1/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create document pemissions',
      },
    }));
};

const addDocumentPermissions = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/article/permissions/${id}/v1.0/`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to create document pemissions',
      },
    }));
};

const deleteDocumentPermissions = (id, data) => {
  return axios({
    url: `${dmsUrl}/api/article/permissions/${id}/v1.1/`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to delete document pemissions',
      },
    }));
};

const createDocument = (data, token) => {
  return axios
    .post(`${dmsUrl}/api/article`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => {
      if (data.type === 'file') {
        window.lastDoc = response.data.id;
      }
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateDocument = (id, data, token) => {
  return axios
    .put(`${dmsUrl}/api/article/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteDocument = (id, token) => {
  return axios({
    method: 'delete',
    url: `${dmsUrl}/api/article/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getUserFeed = (data) => {
  let requestUrl = `${dmsUrl}/api/news/filter/feed/v1.1/?size=${
    data.size || 100
  }&page=${data.page || 0}&userUUID=${data.userUuid}&isFavorite=${
    data.isFavorite
  }`;
  if (data.titleContains)
    requestUrl += `&titleContains=${get(data, 'titleContains', '')}`;
  if (data.sortBy)
    requestUrl += `&sort.${data.sortBy}.direction=${get(
      data,
      'sortDirection',
      ''
    )}&sort.${data.sortBy}.order=0`;
  return axios
    .get(requestUrl)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const sendDirectPush = (data) => {
  return axios
    .post(`${dmsUrl}/api/news-feed/notification/direct`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Fehler. Push-Benachrichtigung wurde nicht gesendet.',
      },
    }));
};

const sendDirectPushTemplate = (data) => {
  return axios
    .post(`${dmsUrl}/api/news-feed/message/direct`, data)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Fehler. Push-Benachrichtigung wurde nicht gesendet.',
      },
    }));
};

const uploadDocumentFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);
  return axios({
    method: 'post',
    headers: {
      'X-File-Length': data.size,
    },
    url: `${dmsUrl}/api/files/temp/upload/${dmsApiVersion}/`,
    data: payload,
  })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const batchUpdateDocumentPath = (data, token) => {
  return axios
    .put(`${dmsUrl}/api/article`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createComment = (data, token) => {
  return axios
    .post(`${dmsUrl}/api/news/comment/${data.id}/v1.0/`, data.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateComment = (data, token) => {
  return axios
    .put(
      `${dmsUrl}/api/news/comment/${data.id}/${data.commentId}/v1.0/`,
      data.payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    )
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteComment = (data, token) => {
  return axios({
    url: `${dmsUrl}/api/news/comment/${data.id}/${data.commentId}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getNewsList = (data) => {
  let requestUrl = `${dmsUrl}/api/news/filter/${dmsApiVersion}/?size=${
    data.size || 100
  }&page=${data.page || 0}`;
  if (data.titleContains)
    requestUrl += `&titleContains=${get(data, 'titleContains', '')}`;
  if (data.category) requestUrl += `&category=${get(data, 'category', '')}`;
  if (data.sortBy)
    requestUrl += `&sort.${data.sortBy}.direction=${get(
      data,
      'sortDirection',
      ''
    )}&sort.${data.sortBy}.order=0`;
  return axios
    .get(requestUrl)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getNewsTemplates = () => {
  return axios
    .get(`${dmsUrl}/api/news/message/template/v1.1/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getNews = (id, token) => {
  return axios
    .get(`${dmsUrl}/api/news/full/${id}/v1.1/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getNewsByVersion = (data) => {
  return axios
    .get(`${dmsUrl}/api/news/version/${data.id}/${data.versionId}/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteNews = (id, token) => {
  return axios({
    url: `${dmsUrl}/api/news/${id}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })
    .then((response) => ({ response: response.data || 'success' }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nachrichten können nicht gelöscht werden',
      },
    }));
};

const deleteNewsList = (data, token) => {
  return axios
    .delete(`${dmsUrl}/api/news/bulk`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
      withCredentials: true,
    })
    .then((response) => ({ response: response.data || 'success' }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Nachrichten können nicht gelöscht werden',
      },
    }));
};

const createNews = (data, token) => {
  return axios
    .post(`${dmsUrl}/api/news/v1.0/`, data.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateNews = (id, data, token) => {
  return axios
    .put(`${dmsUrl}/api/news/${id}`, data.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      withCredentials: true,
    })
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateNewsPartially = (id, data) => {
  return axios
    .patch(`${dmsUrl}/api/news/${id}/v1.0/`, data)
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const addGroupsToNews = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/news/${id}/groups/v1.0/`, data)
    .then((response) => ({ response: { ...response.data } }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const cancelPublication = (id) => {
  return axios
    .put(`${dmsUrl}/api/news/cancel-publication/${id}/v1.0/`)
    .then((response) => ({ response: { ...response.data } }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const setPublicationDate = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/news/publish/${id}/v1.0/`, data)
    .then((response) => ({ response: { ...response.data } }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getProducts = (data) => {
  let requestUrl = `${medicineServiceUrl}/api/product/filter/${medicineServiceVersion}/?size=${
    data.size || 100
  }&page=${data.page || 0}`;
  if (data.category) requestUrl += `&category=${get(data, 'category', '')}`;
  if (data.productNameContains && data.productNameContains.length)
    requestUrl += `&productNameContains=${get(
      data,
      'productNameContains',
      ''
    )}`;
  if (data.sortBy)
    requestUrl += `&sort.${data.sortBy}.direction=${get(
      data,
      'sortDirection',
      ''
    )}&sort.${data.sortBy}.order=0`;
  return axios({
    method: 'get',
    url: requestUrl,
  })
    .then((response) => ({
      response: response.data,
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getFilteredProducts = (data) => {
  return axios({
    method: 'get',
    url: `${medicineServiceUrl}/api/product/search/${medicineServiceVersion}/?count=1000&searchBy=productNameContains&value=${data.searchValue}`,
  })
    .then((response) => ({
      response: response.data,
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getProduct = (id) => {
  return axios({
    method: 'get',
    url: `${medicineServiceUrl}/api/product/${id}/${medicineServiceVersion}/`,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createProduct = (data) => {
  return axios({
    method: 'post',
    url: `${medicineServiceUrl}/api/product/${medicineServiceVersion}/`,
    data: data.payload,
  })
    .then((response) => ({
      response: { ...response.data, redirect: data.redirect },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.errorMessage')
          ? get(err, 'response.data.errorMessage')
          : 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateProduct = async (id, data) => {
  return axios({
    method: 'put',
    url: `${medicineServiceUrl}/api/product/${id}/${medicineServiceVersion}/`,
    data: data.payload,
  })
    .then((response) => ({
      response: { ...response.data, redirect: data.redirect },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.errorMessage')
          ? get(err, 'response.data.errorMessage')
          : 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteProduct = (id) => {
  return axios({
    method: 'delete',
    url: `${medicineServiceUrl}/api/product/${id}/${medicineServiceVersion}/`,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const setProductCategories = (id, data) => {
  return axios
    .put(
      `${medicineServiceUrl}/api/product/${id}/categories/${medicineServiceVersion}/`,
      data
    )
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const uploadProductFile = (id, data) => {
  const payload = new FormData();
  payload.append('file', data, data.name);
  return axios({
    method: 'post',
    headers: {
      'X-File-Length': data.size,
    },
    url: `${medicineServiceUrl}/api/product/file/${id}/upload-pdf/${medicineServiceVersion}/`,
    data: payload,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const uploadProductContentFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);
  return axios({
    method: 'post',
    headers: {
      'X-File-Length': data.size,
    },
    url: `${medicineServiceUrl}/api/product/file/news/upload/${medicineServiceVersion}/`,
    data: payload,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const uploadNewsContentFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);
  return axios({
    method: 'post',
    headers: {
      'X-File-Length': data.size,
    },
    url: `${medicineServiceUrl}/api/product/file/news/upload/${medicineServiceVersion}/`,
    data: payload,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteProductFile = (data) => {
  return axios({
    method: 'delete',
    url: `${medicineServiceUrl}/api/product/file/${data.id}/pdf/${data.key}/${medicineServiceVersion}/`,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const countProducts = () => {
  return axios({
    method: 'get',
    url: `${medicineServiceUrl}/api/product/count/${medicineServiceVersion}/`,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getProductFileDownloadLink = (data) => {
  return axios({
    method: 'get',
    url: `${medicineServiceUrl}/api/product/file/download-link/${data.dir}/${data.key}/${medicineServiceVersion}/`,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getProductConstants = () => {
  return axios({
    method: 'get',
    url: `${medicineServiceUrl}/api/constants/product/${medicineServiceVersion}/`,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const uploadProductCSV = (data) => {
  const payload = new FormData();
  payload.append('file', data, 'products.csv');
  return axios({
    method: 'post',
    url: `${medicineServiceUrl}/api/product/import-csv/${medicineServiceVersion}/?active=true`,
    data: payload,
  })
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(
          err,
          'response.data.errorMessage',
          'Die Anfrage ist fehlgeschlagen'
        ),
      },
    }));
};

// NEWS GROUP

const getGroups = () => {
  return axios
    .get(`${userServiceUrl}/api/users/group/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getGroup = (id) => {
  return axios
    .get(`${userServiceUrl}/api/users/group/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createGroup = (data) => {
  return axios
    .post(`${userServiceUrl}/api/users/group/v1.0/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteGroup = (id) => {
  return axios
    .delete(`${userServiceUrl}/api/users/group/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateGroup = (id, data) => {
  return axios({
    method: 'put',
    url: `${userServiceUrl}/api/users/group/${id}/v1.0/`,
    data: data,
  })
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

// const getGroupUsers = (data) => {
//   return axios
//     .get(`${ userServiceUrl }/api/users/filter/${ dmsApiVersion }/?permissionGroupUuid=${ data.groupUuid }&page=${data.page || 0}&size=${data.size || 300}&sort.firstName.direction=ASC&sort.firstName.order=0`)
//     .then(response => {
//       return ({response: response.data})
//     })
//     .catch(err => ({
//       error: {
//         ...get(err, "response.data", {}),
//         message: "Failed to get permission groups"
//       }
//     }))
// };

const addUsersToGroup = (data) => {
  return axios
    .put(
      `${userServiceUrl}/api/users/group/${data.groupId}/users/${dmsApiVersion}/`,
      data.users
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteUsersFromGroup = (data) => {
  return axios
    .delete(
      `${userServiceUrl}/api/users/group/${data.groupId}/users/${dmsApiVersion}/`,
      {
        data: data.users,
      }
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getCalendarEntries = (data) => {
  return axios
    .get(
      `${calendarServiceUrl}/api/calendars/filter/${dmsApiVersion}/?userUUID=${data.userUuid}&startDateTimeMax=${data.start}&endDateTimeMin=${data.end}`
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Fehler beim Herunterladen des Kalenders.',
      },
    }));
};

const createCalendarEntry = (data) => {
  return axios
    .post(
      `${calendarServiceUrl}/api/calendars/users/${data.userUuid}/${dmsApiVersion}/`,
      data.payload
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Fehler: Es konnte kein Ereignis erstellt werden.',
      },
    }));
};

const updateCalendarEntry = (data) => {
  return axios
    .put(
      `${calendarServiceUrl}/api/calendars/${data.calendarId}/${dmsApiVersion}/`,
      data.payload
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Fehler: Es konnte kein Ereignis erstellt werden.',
      },
    }));
};

const deleteCalendarEntry = (id) => {
  return axios
    .delete(`${calendarServiceUrl}/api/calendars/${id}/${dmsApiVersion}/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Fehler: Es konnte kein Ereignis erstellt werden.',
      },
    }));
};

const copyNews = (id) => {
  return axios
    .post(`${dmsUrl}/api/news/copy/${id}`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Beim kopieren des gewählten Contents trat ein Fehler auf.',
      },
    }));
};

const getCategories = () => {
  return axios
    .get(`${dmsUrl}/api/categories/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getCategory = (name) => {
  return axios
    .get(`${dmsUrl}/api/categories/${name}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createCategory = (data) => {
  return axios
    .post(`${dmsUrl}/api/categories/v1.0/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateCategory = (name, data) => {
  return axios
    .put(`${dmsUrl}/api/categories/${name}/v1.0/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteCategory = (name) => {
  return axios
    .delete(`${dmsUrl}/api/categories/${name}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getTags = () => {
  return axios
    .get(`${userServiceUrl}/api/tags/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getTag = (name) => {
  return axios
    .get(`${userServiceUrl}/api/tags/name/${name}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createTag = (data) => {
  return axios
    .post(`${userServiceUrl}/api/tags/v1.0/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateTag = (data) => {
  return axios
    .put(`${userServiceUrl}/api/tags/v1.0/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteTag = (name) => {
  return axios
    .delete(`${userServiceUrl}/api/tags/name/${name}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const addTagToUser = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/users/uuid/${id}/tags/add/v1.0/`, data)
    .then((response) => {
      return { response: { ...response.data } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteTagFromUser = (id, data) => {
  return axios
    .put(`${userServiceUrl}/api/users/uuid/${id}/tags/remove/v1.0/`, data)
    .then((response) => {
      return { response: { ...response.data } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getNewsCategories = () => {
  return axios
    .get(`${dmsUrl}/api/categories/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getNewsCategory = (name) => {
  return axios
    .get(`${dmsUrl}/api/categories/${name}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createNewsCategory = (data) => {
  return axios
    .post(`${dmsUrl}/api/categories/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateNewsCategory = (name, data) => {
  return axios
    .put(`${dmsUrl}/api/categories/${name}/v1.0/`, omit(data, 'name'))
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteNewsCategory = (name) => {
  return axios
    .delete(`${dmsUrl}/api/categories/${name}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const setNewsCategories = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/news/${id}/categories/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const setNewsTags = (id, data) => {
  return axios
    .put(`${dmsUrl}/api/news/${id}/tags/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getWorkflowList = (data) => {
  let requestUrl = `${dmsUrl}/api/news/work-flow/filter/${dmsApiVersion}/?size=${
    data.size || 100
  }&page=${data.page || 0}`;
  if (data.titleContains && data.titleContains.length)
    requestUrl += `&titleContains=${get(data, 'titleContains', '')}`;
  if (data.category) requestUrl += `&category=${get(data, 'category', '')}`;
  if (data.status) requestUrl += `&creationStatus=${get(data, 'status', '')}`;
  if (data.assignment)
    requestUrl += `&assignment=${get(data, 'assignment', '')}`;
  if (data.sortBy)
    requestUrl += `&sort.${data.sortBy}.direction=${get(
      data,
      'sortDirection',
      ''
    )}&sort.${data.sortBy}.order=0`;
  return axios
    .get(requestUrl)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
      },
    }));
};

const pickupNews = (data) => {
  return axios
    .put(
      `${dmsUrl}/api/news/work-flow/assign-to-user/v1.0/?newsId=${data.newsId}&forceAssign=${data.forceAssign}`
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => {
      return {
        error: {
          ...get(err, 'response.data', {}),
          message: get(err, 'response.data.message')
            ? get(err, 'response.data.message')
            : 'Die Anfrage ist fehlgeschlagen.',
        },
      };
    });
};

const approveNews = (id) => {
  return axios
    .put(`${dmsUrl}/api/news/work-flow/approve/v1.0/?newsId=${id}`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const rejectNews = (id) => {
  return axios
    .put(`${dmsUrl}/api/news/work-flow/reject/v1.0/?newsId=${id}`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const updateLatestVersion = (id, data) => {
  return axios
    .put(
      `${dmsUrl}/api/news/work-flow/latest-version/v1.0/?newsId=${id}`,
      omit(data.payload, ['options', 'callback'])
    )
    .then((response) => ({
      response: {
        ...response.data,
        options: data.options,
        callback: data.callback,
      },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const createNewVersion = (id, data) => {
  return axios
    .put(
      `${dmsUrl}/api/news/work-flow/new-version/v1.0/?newsId=${id}`,
      data.payload
    )
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const createNewNewsVersion = (data) => {
  return axios
    .put(`${dmsUrl}/api/news/work-flow/latest-version/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const downloadPdfNews = (data) => {
  return axios
    .post(`${dmsUrl}/api/pdf/news`, data, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const downloadPdfProducts = (data) => {
  return axios
    .post(`${medicineServiceUrl}/api/products/pdf/`, data, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const getWorkflowHistory = (id) => {
  return axios
    .get(`${dmsUrl}/api/news/work-flow/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const getTenants = () => {
  return axios
    .get(`${dmsUrl}/api/tenants/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const getTenant = (id) => {
  return axios
    .get(`${dmsUrl}/api/tenants/key/${id}/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const createTenant = (data) => {
  return axios
    .post(`${dmsUrl}/api/tenants/v1.0/`, data)
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const updateTenant = (data) => {
  return axios
    .put(`${dmsUrl}/api/tenants/v1.0/`, omit(data, 'options'))
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const getCampaigns = () => {
  return axios
    .get(`${campaignServiceUrl}/api/campaigntemplate/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const getCampaign = (id) => {
  return axios
    .get(`${campaignServiceUrl}/api/campaigntemplate/${id}/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const getCampaignByVersion = (data) => {
  return axios
    .get(
      `${campaignServiceUrl}/api/campaigntemplate/${data.id}/${data.versionId}/v1.0/`
    )
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const createCampaign = (data) => {
  return axios
    .post(`${campaignServiceUrl}/api/campaigntemplate/v1.0/`, data)
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const updateCampaign = (data) => {
  return axios
    .put(`${campaignServiceUrl}/api/campaigntemplate/v1.0/`, data)
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const startCampaign = (data) => {
  return axios
    .post(`${campaignServiceUrl}/api/campaign/v1.0/`, data)
    .then((response) => ({
      response: { ...response.data, options: data.options },
    }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: get(err, 'response.data.message')
          ? get(err, 'response.data.message')
          : 'Die Anfrage ist fehlgeschlagen.',
      },
    }));
};

const getAttributes = () => {
  return axios
    .get(`${userServiceUrl}/api/user-attributes/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getAttribute = (id) => {
  return axios
    .get(`${userServiceUrl}/api/user-attributes/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createAttribute = (data) => {
  return axios
    .post(`${userServiceUrl}/api/user-attributes/v1.0/`, omit(data, 'options'))
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateAttribute = (id, data) => {
  return axios
    .put(
      `${userServiceUrl}/api/user-attributes/${id}/v1.0/`,
      omit(data, 'options')
    )
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteAttribute = (id) => {
  return axios
    .delete(`${userServiceUrl}/api/user-attributes/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getUserAttributes = (userUuid) => {
  return axios
    .get(`${userServiceUrl}/api/users/uuid/${userUuid}/attributes/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getUserAttributeHistory = (data) => {
  return axios
    .get(
      `${userServiceUrl}/api/users/uuid/${data.userUuid}/attributes/history/${data.attributeId}/v1.0/`
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const addAttributeToUser = (userUuid, data) => {
  return axios
    .put(
      `${userServiceUrl}/api/users/uuid/${userUuid}/attributes/set/v1.0/`,
      data
    )
    .then((response) => {
      return { response: { ...response.data, isHistory: data.isHistory } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteAttributeFromUser = (data) => {
  return axios
    .put(
      `${userServiceUrl}/api/users/uuid/${data.userUuid}/attributes/empty/${data.attributeDefId}/v1.0/`
    )
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getSurveys = () => {
  return axios
    .get(`${surveyServiceUrl}/api/survey/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to load surveys',
      },
    }));
};

const getSurvey = (id) => {
  return axios
    .get(`${surveyServiceUrl}/api/survey/${id}/v1.0/`)
    .then((response) => ({ response: response.data }))
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Failed to load surveys',
      },
    }));
};

const createSurvey = (data) => {
  return axios
    .post(`${surveyServiceUrl}/api/survey/v1.0/`, data)
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateSurvey = (data) => {
  return axios
    .put(`${surveyServiceUrl}/api/survey/v1.0/`, data)
    .then((response) => {
      return { response: { ...response.data, options: data.options } };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteSurvey = (id) => {
  return axios
    .delete(`${surveyServiceUrl}/api/survey/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const sendDirectSurvey = (data) => {
  return axios
    .post(`${dmsUrl}/api/news-feed/survey/direct`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getUserCampaigns = (data) => {
  let requestUrl = `${campaignServiceUrl}/api/campaign/userUuid/${
    data.userUuid
  }/active/filter/v1.0/?size=${data.size || 100}&page=${data.page || 0}`;
  if (data.templateName && data.templateName.length)
    requestUrl += `&templateName=${get(data, 'templateName')}`;
  if (data.status && data.status.length)
    requestUrl += `&status=${get(data, 'status')}`;
  if (data.sortBy)
    requestUrl += `&sort.${data.sortBy}.direction=${get(
      data,
      'sortDirection',
      ''
    )}&sort.${data.sortBy}.order=0`;
  return axios
    .get(requestUrl)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getUserArchivedCampaigns = (data) => {
  let requestUrl = `${campaignServiceUrl}/api/campaign/userUuid/${
    data.userUuid
  }/archive/filter/v1.0/?size=${data.size || 100}&page=${data.page || 0}`;
  if (data.templateName && data.templateName.length)
    requestUrl += `&templateName=${get(data, 'templateName')}`;
  if (data.status && data.status.length)
    requestUrl += `&status=${get(data, 'status')}`;
  if (data.sortBy)
    requestUrl += `&sort.${data.sortBy}.direction=${get(
      data,
      'sortDirection',
      ''
    )}&sort.${data.sortBy}.order=0`;
  return axios
    .get(requestUrl)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') ||
          'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  login,
  refreshToken,
  getUsers,
  getUser,
  getUserWithGroups,
  updateUser,
  createUser,
  deleteUser,
  deleteUsers,
  requestPasswordReset,
  resetPassword,
  getWizardByCorrelationId,
  checkRegistrationStatus,
  getAlcoNorms,
  getGoals,
  getSurveys,
  saveSimulation,
  simulateSurvey,
  updateWizard,
  getDocuments,
  getNewsByVersion,
  getDocument,
  getDocumentByVersion,
  getDocumentPermissions,
  createDocument,
  updateDocument,
  deleteDocument,
  uploadDocumentFile,
  batchUpdateDocumentPath,
  createComment,
  updateComment,
  deleteComment,
  getNewsList,
  getNews,
  deleteNews,
  createNews,
  updateNews,
  updateNewsPartially,
  deleteNewsList,
  getProducts,
  getFilteredProducts,
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
  setProductCategories,
  uploadProductFile,
  countProducts,
  getProductConstants,
  deleteProductFile,
  getProductFileDownloadLink,
  uploadProductCSV,
  dmsApiVersion,
  getGroups,
  getGroup,
  createGroup,
  deleteGroup,
  updateGroup,
  addUsersToGroup,
  deleteUsersFromGroup,
  getCalendarEntries,
  updateCalendarEntry,
  createCalendarEntry,
  deleteCalendarEntry,
  uploadProductContentFile,
  copyNews,
  updateUserPassword,
  createDocumentPermissions,
  deleteDocumentPermissions,
  addDocumentPermissions,
  sendDirectPush,
  getNewsCategories,
  getNewsCategory,
  setNewsCategories,
  updateNewsCategory,
  deleteNewsCategory,
  getCategories,
  getCategory,
  updateCategory,
  deleteCategory,
  getUserFeed,
  getUserProducts,
  createNewsCategory,
  getWorkflowList,
  approveNews,
  pickupNews,
  rejectNews,
  createNewNewsVersion,
  updateLatestVersion,
  createNewVersion,
  getWorkflowHistory,
  addGroupsToNews,
  cancelPublication,
  setPublicationDate,
  createCategory,
  getTenant,
  getTenants,
  updateTenant,
  createTenant,
  getCampaign,
  getCampaignByVersion,
  getCampaigns,
  startCampaign,
  updateCampaign,
  createCampaign,
  getTag,
  getTags,
  createTag,
  setNewsTags,
  updateTag,
  deleteTag,
  addTagToUser,
  deleteTagFromUser,
  getAttributes,
  getAttribute,
  createAttribute,
  updateAttribute,
  deleteAttribute,
  getUserAttributes,
  addAttributeToUser,
  deleteAttributeFromUser,
  getUserAttributeHistory,
  getSurvey,
  createSurvey,
  updateSurvey,
  deleteSurvey,
  getNewsTemplates,
  getUserCampaigns,
  getUserArchivedCampaigns,
  sendDirectSurvey,
  sendDirectPushTemplate,
  downloadPdfNews,
  downloadPdfProducts,
};
