import { takeEvery, select } from 'redux-saga/effects';
import { apiRequest } from './index';
import api from 'utils/api';
import * as productActions from 'actions/Product';
import { get } from 'lodash';
import history from 'utils/history';
import { Slide, toast } from 'react-toastify';
import downloadFile from '../utils/downloadFile';

const getProducts = apiRequest.bind(
  null,
  productActions.getProducts,
  api.getProducts
);
const getFilteredProducts = apiRequest.bind(
  null,
  productActions.getFilteredProducts,
  api.getFilteredProducts
);
const getProduct = apiRequest.bind(
  null,
  productActions.getProduct,
  api.getProduct
);
const createProduct = apiRequest.bind(
  null,
  productActions.createProduct,
  api.createProduct
);
const updateProduct = apiRequest.bind(
  null,
  productActions.updateProduct,
  api.updateProduct
);
const deleteProduct = apiRequest.bind(
  null,
  productActions.deleteProduct,
  api.deleteProduct
);
const getProductConstants = apiRequest.bind(
  null,
  productActions.getProductConstants,
  api.getProductConstants
);
const setProductCategories = apiRequest.bind(
  null,
  productActions.setProductCategories,
  api.setProductCategories
);

const downloadPdfRequest = apiRequest.bind(
  null,
  productActions.downloadPdf,
  api.downloadPdfProducts
);

function downloadPdf({ payload }) {
  downloadFile(payload, 'pdf', 'Products PDF.pdf');
}

function* refreshProducts() {
  const state = yield select();
  const arg = {
    ...get(arguments, '0', {}),
    data: {
      page: get(state, 'Product.productsPage', 0),
    },
  };
  yield apiRequest.apply(null, [
    productActions.getProducts,
    api.getProducts,
    arg,
  ]);
}

function* handleUpdateProduct(action) {
  if (action.payload.redirect) {
    yield history.push('/products');
  } else {
    yield history.push(`/edit-product/${action.payload.id}`);

    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: 'bottom-center',
      type: 'success',
    });
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetProductsRequest() {
  yield takeEvery(productActions.GET_PRODUCT_LIST.REQUEST, getProducts);
}

export function* watchGetFilteredProductsRequest() {
  yield takeEvery(
    productActions.GET_FILTERED_PRODUCT_LIST.REQUEST,
    getFilteredProducts
  );
}

export function* watchGetProductRequest() {
  yield takeEvery(productActions.GET_PRODUCT.REQUEST, getProduct);
}

export function* watchCreateProductRequest() {
  yield takeEvery(productActions.CREATE_PRODUCT.REQUEST, createProduct);
}

export function* watchUpdateProductRequest() {
  yield takeEvery(productActions.UPDATE_PRODUCT.REQUEST, updateProduct);
}

export function* watchUpdateProductSuccess() {
  yield takeEvery(productActions.UPDATE_PRODUCT.SUCCESS, handleUpdateProduct);
}

export function* watchDeleteProductRequest() {
  yield takeEvery(productActions.DELETE_PRODUCT.REQUEST, deleteProduct);
}

export function* watchDeleteProductSuccess() {
  yield takeEvery(productActions.DELETE_PRODUCT.SUCCESS, refreshProducts);
}

export function* watchCreateProductSuccess() {
  yield takeEvery(productActions.CREATE_PRODUCT.SUCCESS, handleUpdateProduct);
}

export function* watchGetProductConstantsRequest() {
  yield takeEvery(
    productActions.GET_PRODUCT_CONSTANTS.REQUEST,
    getProductConstants
  );
}

export function* watchSetProductCategoriesRequest() {
  yield takeEvery(
    productActions.SET_PRODUCT_CATEGORIES.REQUEST,
    setProductCategories
  );
}

export function* watchSetProductCategoriesSuccess() {
  yield takeEvery(
    productActions.SET_PRODUCT_CATEGORIES.SUCCESS,
    handleUpdateProduct
  );
}

export function* watchSendProductsIdsToDownloadPdfRequest() {
  yield takeEvery(
    productActions.DOWNLOAD_PDF_PRODUCT.REQUEST,
    downloadPdfRequest
  );
}

export function* watchSendProductsIdsToDownloadPdfSuccess() {
  yield takeEvery(productActions.DOWNLOAD_PDF_PRODUCT.SUCCESS, downloadPdf);
}
