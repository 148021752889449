import axios from 'axios';
import { get } from 'lodash';

import { cubeServiceUrl } from 'config/environment';

const getCubes = () => {
  return axios
    .get(`${cubeServiceUrl}/api/cube/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getCube = (id) => {
  return axios
    .get(`${cubeServiceUrl}/api/cube/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const createCube = (data) => {
  return axios
    .post(`${cubeServiceUrl}/api/cube/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const updateCube = (data, id) => {
  return axios
    .put(`${cubeServiceUrl}/api/cube/${id}/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const deleteCube = (id) => {
  return axios
    .delete(`${cubeServiceUrl}/api/cube/${id}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getHealthAssistants = () => {
  return axios
    .get(`${cubeServiceUrl}/api/healthAssistant/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const getUserCubes = (userUuid) => {
  return axios
    .get(`${cubeServiceUrl}/api/usercube/user/${userUuid}/v1.0/`)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const startCube = (data) => {
  return axios
    .post(`${cubeServiceUrl}/api/usercube/v1.0/`, data)
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message:
          get(err, 'response.data.message', 'Die Anfrage ist fehlgeschlagen') || 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

const uploadDocumentFile = (data) => {
  const payload = new FormData();
  payload.append('file', data);
  return axios({
    method: 'post',
    headers: {
      'X-File-Length': data.size,
    },
    url: `${cubeServiceUrl}/api/files/temp/upload/v1.0/`,
    data: payload,
  })
    .then((response) => {
      return { response: response.data };
    })
    .catch((err) => ({
      error: {
        ...get(err, 'response.data', {}),
        message: 'Die Anfrage ist fehlgeschlagen',
      },
    }));
};

export default {
  getCubes,
  getCube,
  getHealthAssistants,
  createCube,
  updateCube,
  deleteCube,
  uploadDocumentFile,
  getUserCubes,
  startCube
};
