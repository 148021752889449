import React from "react";
import Nav from "../../AppNav/VerticalNavWrapper";

const Component = ({ open = false, closeMenu, nav = [], history }) => (
  <div className={`d-mobile-menu-overlay ${open ? "open" : ""}`}>
    <div onClick={closeMenu}>
      <Nav />
    </div>
  </div>
);

export default Component;
