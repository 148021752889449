import { takeEvery, all, put } from 'redux-saga/effects';
import { apiRequest } from './index';
import CubeApi from '../api/Cube';
import * as cubeActions from 'actions/Cube';
import { Slide, toast } from 'react-toastify';
import history from 'utils/history';

const getCubes = apiRequest.bind(null, cubeActions.getCubes, CubeApi.getCubes);
const getCube = apiRequest.bind(null, cubeActions.getCube, CubeApi.getCube);
const deleteCube = apiRequest.bind(null, cubeActions.deleteCube, CubeApi.deleteCube);
const getUserCubes = apiRequest.bind(null, cubeActions.getUserCubes, CubeApi.getUserCubes);

function* createCube({ data, options }) {
  const { response, error } = yield CubeApi.createCube(data);
  if (error) {
    yield put({
      type: cubeActions.CREATE_CUBE.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeActions.CREATE_CUBE.SUCCESS,
    payload: response,
  });

  if (options === 'redirect') {
    yield history.push('/cubes');
  } else {
    yield history.replace(`/update-cube/${response.id}`);
  }
}

function* startCube({ data }) {
  const { response, error } = yield CubeApi.startCube(data);
  if (error) {
    yield put({
      type: cubeActions.START_CUBE.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeActions.START_CUBE.SUCCESS,
    id: response.userUuid,
    payload: response
  });
}

function* updateCube({ id, data, meta }) {
  const { response, error } = yield CubeApi.updateCube(data, id);
  if (error) {
    yield put({
      type: cubeActions.UPDATE_CUBE.FAILURE,
      payload: error,
    });
    return;
  }
  yield put({
    type: cubeActions.UPDATE_CUBE.SUCCESS,
    payload: response,
  });

  if (meta === 'redirect') {
    yield history.push('/cubes');
  }
}

const getHealthAssistants = apiRequest.bind(null, cubeActions.getHealthAssistants, CubeApi.getHealthAssistants);

const successSaveHandler = () => {
  toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: 'bottom-center',
    type: 'success',
  });
};

/******************************************************************************/
/******************************* WATCHERS *************************************/

/******************************************************************************/
export function* rootWatcherCube() {
  yield all([
    takeEvery(cubeActions.GET_CUBES.REQUEST, getCubes),
    takeEvery(cubeActions.GET_CUBE.REQUEST, getCube),
    takeEvery(cubeActions.UPDATE_CUBE.REQUEST, updateCube),
    takeEvery(cubeActions.GET_HEALTH_ASSISTANTS.REQUEST, getHealthAssistants),
    takeEvery(cubeActions.CREATE_CUBE.REQUEST, createCube),
    takeEvery(cubeActions.DELETE_CUBE.REQUEST, deleteCube),
    takeEvery(cubeActions.GET_USER_CUBES.REQUEST, getUserCubes),
    takeEvery(cubeActions.START_CUBE.REQUEST, startCube),
    takeEvery(cubeActions.UPDATE_CUBE.SUCCESS, successSaveHandler),
    takeEvery(cubeActions.CREATE_CUBE.SUCCESS, successSaveHandler),
    takeEvery(cubeActions.START_CUBE.SUCCESS, successSaveHandler),
    takeEvery(cubeActions.START_CUBE.SUCCESS, getUserCubes),
  ]);
}
