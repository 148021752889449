import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import toDataUrl from "utils/toDataUrl";
import moment from "moment";

import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MobileMenuOverlay from "./Components/MobileMenuOverlay";
import {NavItems} from "../AppNav/NavItems";

import powerDown from "assets/utils/images/logout.svg";

import { logout } from "../../actions/Auth";
import { connect } from "react-redux";
import { get } from "lodash";

import minilogo from "../../assets/utils/images/minilogo.svg";
import hamburger_menu from "../../assets/utils/images/hamburger_menu.svg";
import hamburger_menu_open from "../../assets/utils/images/hamburger_menu_open.svg";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const getNav = (userRole) => {
  return NavItems
};

const pagesWithFixedHeader = [
  "/update-file",
  "/create-file",
  "/view-file",
  "/create-news",
  "/create-user",
  "/update-news",
  "/view-news",
  "/view-group",
  "/edit-group",
  "/edit-tenant",
  "/create-tenant",
  "/view-tenant",
  "/edit-category",
  "/view-category",
  "/create-survey",
  "/view-survey",
  "/edit-survey",
  "/edit-news",
  "/edit-product",
  "/edit-user",
  "/view-user",
  "/create-campaign",
  "/create-tag",
  "/edit-tag",
  "/view-tag",
  "/edit-campaign",
  "/view-campaign",
  "/view-product",
  "/create-product",
];

const checkHeaderFixed = (history) => {
  const path = get(history, "location.pathname");
  return pagesWithFixedHeader.reduce((acc, el) => {
    if (acc) return acc;
    if (path.includes(el)) return true;
    return acc;
  }, false);
};

const Component = ({
  logout,
  noTabs = false,
  noUser = false,
  user,
  userRole,
  history,
  location,
  darkTheme,
  startPdfDownload,
  endPdfDownload,
}) => {
  const [menuOpen, toogleMenuVisibility] = useState(false);

  const toggleMenu = (state) => {
    const body = document.querySelector("body");
    if (state === true) {
      disableBodyScroll(body);
    } else {
      enableBodyScroll(body);
    }
    toogleMenuVisibility(state);
  };

  const isHeaderFixed = checkHeaderFixed(history);

  const downloadPdf = () => {
    startPdfDownload();
    toDataUrl(`${process.env.PUBLIC_URL}/pdfimg1.png`, (img1) => {
      toDataUrl(`${process.env.PUBLIC_URL}/pdfimg2.png`, (img2) => {
        toDataUrl(`${process.env.PUBLIC_URL}/viasanitas.png`, (data) => {
          toDataUrl(`${process.env.PUBLIC_URL}/b24Image.png`, (logoData) => {
            var opt = {
              margin: [35, 3, 3, 3],
              filename: "iQest.pdf",
              image: { type: "jpeg", quality: 0.9 },
              html2canvas: { scale: 2 },
              jsPDF: { orientation: "landscape" },
            };

            window
              .html2pdf()
              .set(opt)
              .from(document.querySelector(".d-score-page"))
              .toPdf()
              .get("pdf")
              .then(function (pdf) {
                pdf.addPage();
                pdf.addImage(img1, "PNG", 29, 35, 240, 140, "", "FAST");
                pdf.addPage();
                pdf.addImage(img2, "PNG", 29, 35, 240, 140, "", "FAST");
                var totalPages = pdf.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                  pdf.setPage(i);
                  pdf.setFontSize(10);
                  pdf.setTextColor(150);
                  pdf.text(
                    moment().format("DD.MM.YYYY"),
                    140,
                    pdf.internal.pageSize.getHeight() - 10
                  );
                  pdf.addImage(
                    data,
                    "PNG",
                    20,
                    pdf.internal.pageSize.getHeight() - 15,
                    50,
                    7,
                    "",
                    "FAST"
                  );
                  pdf.addImage(logoData, "PNG", 125, 5, 45, 25, "", "FAST");
                  pdf.text(
                    "Seite " + i + " von " + totalPages,
                    pdf.internal.pageSize.getWidth() - 40,
                    pdf.internal.pageSize.getHeight() - 10
                  );
                }
              })
              .save()
              .then(() => endPdfDownload());
          });
        });
      });
    });
  };

  return (
    <>
      <div
        className={`d-header
            ${userRole === "user" ? "user-header" : "admin-header"}
            ${isHeaderFixed ? "d-header-fixed" : ""}
        `}
      >
        <div className="d-header-top">
          {userRole !== "user" && (
            <div className="d-user-text">{`Willkommen ${get(
              user,
              "name",
              get(user, "preferred_username", "")
            )}!`}
            <p style={{position: "absolute", fontSize: 14}}>{get(user, "tenant", "")}</p>
            </div>
          )}
          {userRole === "user" && history.location.pathname.includes("score") && (
            <div className="d-header-settings" onClick={downloadPdf}>
              <FontAwesomeIcon icon={faFilePdf} />{" "}
              <span>PDF herunterladen</span>
            </div>
          )}
          {noUser ? (
            <div
              className="d-logout-text"
              onClick={() => history.push("../login")}
            >
              Login
            </div>
          ) : (
            <>
              {user ? (
                <div className="d-logout-text" onClick={() => logout()}>
                  <img src={powerDown} alt="logout" /> Ausloggen
                </div>
              ) : (
                <div
                  className="d-logout-text"
                  onClick={() => history.push("../login")}
                >
                  Login
                </div>
              )}

              <div className="d-logout-img" onClick={() => logout()}>
                <img src={powerDown} alt="logout" />
              </div>
            </>
          )}
        </div>
        <div className="d-header-middle">
          <div
            className={
              menuOpen
                ? "d-header-hamburger d-header-hamburger__open"
                : "d-header-hamburger"
            }
            onClick={() => toggleMenu(!menuOpen)}
          >
            <img
              src={menuOpen ? hamburger_menu_open : hamburger_menu}
              className="d-header-hamburger-icon"
              alt="Hamburger"
            />
          </div>
          {location.pathname !== "/simulation" && (
            <div className="d-header-middle-content">
              <img src={minilogo} className="d-header-minilogo" alt="Logo" />
            </div>
          )}
          {location.pathname === "/simulation" && (
            <div className="d-header-middle-content">
              <img src={minilogo} className="d-header-minilogo" alt="Logo" />
            </div>
          )}
        </div>
        <div className="d-header-bottom">
          {userRole !== "user" && (
            <Link to="/users">
              <div className="d-header-tab">
                <div className="d-about-icon"></div>Kundenmanagement
              </div>
            </Link>
          )}
        </div>
      </div>
      <MobileMenuOverlay
        open={menuOpen}
        closeMenu={() => toggleMenu(false)}
        nav={getNav(userRole)}
      />
    </>
  );
};

const mapStateToProps = ({ UserRoles, Auth, Theme }) => ({
  userRole: UserRoles.userRole,
  user: Auth.user,
  darkTheme: Theme.darkTheme,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout.request()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
