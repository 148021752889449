import { createRequestTypes, action } from "../utils/actions";
import { REQUEST, SUCCESS, FAILURE } from "../utils/constants";

export const GET_ATTRIBUTES = createRequestTypes("GET_ATTRIBUTES");
export const GET_ATTRIBUTE = createRequestTypes("GET_ATTRIBUTE");
export const CREATE_ATTRIBUTE = createRequestTypes("CREATE_ATTRIBUTE");
export const UPDATE_ATTRIBUTE = createRequestTypes("UPDATE_ATTRIBUTE");
export const DELETE_ATTRIBUTE = createRequestTypes("DELETE_ATTRIBUTE");

export const ADD_ATTRIBUTE_TO_USER = createRequestTypes("ADD_ATTRIBUTE_TO_USER");
export const DELETE_ATTRIBUTE_FROM_USER = createRequestTypes("DELETE_ATTRIBUTE_FROM_USER");
export const GET_ATTRIBUTE_USERS = createRequestTypes("GET_ATTRIBUTE_USERS");
export const GET_USER_ATTRIBUTES = createRequestTypes("GET_USER_ATTRIBUTES");
export const GET_USER_ATTRIBUTE_HISTORY = createRequestTypes("GET_USER_ATTRIBUTE_HISTORY");

export const SET_ATTRIBUTES_TABLE_SORTING = 'SET_ATTRIBUTES_TABLE_SORTING';
export const SET_ATTRIBUTES_TABLE_FILTER = 'SET_ATTRIBUTES_TABLE_FILTER';

export const getAttributes = {
  request: () => action(GET_ATTRIBUTES[REQUEST]),
  success: (payload) => action(GET_ATTRIBUTES[SUCCESS], { payload }),
  failure: (payload) => action(GET_ATTRIBUTES[FAILURE], { payload }),
};

export const getAttribute = {
  request: (id) => action(GET_ATTRIBUTE[REQUEST], { id }),
  success: (payload) => action(GET_ATTRIBUTE[SUCCESS], { payload }),
  failure: (payload) => action(GET_ATTRIBUTE[FAILURE], { payload }),
};

export const createAttribute = {
  request: (data) => action(CREATE_ATTRIBUTE[REQUEST], { data }),
  success: (payload) => action(CREATE_ATTRIBUTE[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_ATTRIBUTE[FAILURE], { payload }),
};

export const updateAttribute = {
  request: (id, data) => action(UPDATE_ATTRIBUTE[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_ATTRIBUTE[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_ATTRIBUTE[FAILURE], { payload }),
};

export const deleteAttribute = {
  request: (id) => action(DELETE_ATTRIBUTE[REQUEST], { id }),
  success: (payload) => action(DELETE_ATTRIBUTE[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_ATTRIBUTE[FAILURE], { payload }),
};

export const getAttributeUsers = {
  request: (id) => action(GET_ATTRIBUTE_USERS[REQUEST], {id}),
  success: (payload) => action(GET_ATTRIBUTE_USERS[SUCCESS], {payload}),
  failure: (payload) => action(GET_ATTRIBUTE_USERS[FAILURE], {payload}),
};

export const getUserAttributes = {
  request: (id) => action(GET_USER_ATTRIBUTES[REQUEST], {id}),
  success: (payload) => action(GET_USER_ATTRIBUTES[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_ATTRIBUTES[FAILURE], {payload}),
};

export const addAttributeToUser = {
  request: (id, data) => action(ADD_ATTRIBUTE_TO_USER[REQUEST], {id, data}),
  success: (payload) => action(ADD_ATTRIBUTE_TO_USER[SUCCESS], {payload}),
  failure: (payload) => action(ADD_ATTRIBUTE_TO_USER[FAILURE], {payload}),
}

export const deleteAttributeFromUser = {
  request: (data) => action(DELETE_ATTRIBUTE_FROM_USER[REQUEST], {data}),
  success: (payload) => action(DELETE_ATTRIBUTE_FROM_USER[SUCCESS], {payload}),
  failure: (payload) => action(DELETE_ATTRIBUTE_FROM_USER[FAILURE], {payload}),
}

export const getUserAttributeHistory = {
  request: (data) => action(GET_USER_ATTRIBUTE_HISTORY[REQUEST], {data}),
  success: (payload) => action(GET_USER_ATTRIBUTE_HISTORY[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_ATTRIBUTE_HISTORY[FAILURE], {payload}),
}

export const setAttributesTableFilter = (payload) => ({
  type: SET_ATTRIBUTES_TABLE_FILTER,
  payload
})

export const setAttributesTableSorting = (payload) => ({
  type: SET_ATTRIBUTES_TABLE_SORTING,
  payload
})