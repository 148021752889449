import * as ActionTypes from 'actions/Cube';

const initialState = {
  cubes: [],
  cube: {},
  healthAssistants: [],
  userCubes: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CUBES.SUCCESS:
      return {
        ...state,
        cubes: action.payload,
      };
    case ActionTypes.GET_CUBE.SUCCESS:
      return {
        ...state,
        cube: action.payload,
      };
    case ActionTypes.UPDATE_CUBE.SUCCESS:
      return {
        ...state,
        cube: action.payload,
      };
    case ActionTypes.GET_USER_CUBES.SUCCESS:
      return {
        ...state,
        userCubes: action.payload,
      };
    case ActionTypes.GET_HEALTH_ASSISTANTS.SUCCESS:
      return {
        ...state,
        healthAssistants: action.payload,
      };
    default:
      return state;
  }
}
