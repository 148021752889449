import { Route, Redirect, withRouter } from 'react-router-dom';
import React, { Suspense, lazy, Fragment, useEffect } from 'react';
import Loader from 'react-loaders';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Notification from 'components/Notification';

import { checkAuth } from 'actions/Auth';
import { getDocuments } from 'actions/Documents';
import { getProductConstants } from 'actions/Product';

import AppHeader from '../AppHeader/';
import AppSidebar from '../AppSidebar/';
import AppFooter from '../AppFooter/';

import { ToastContainer } from 'react-toastify';

import Loading from 'components/Loading';
import { setLoading } from '../../actions/Loading';
const UserListPage = lazy(() => import('pages/UserList'));
const NewsPage = lazy(() => import('pages/News'));
const CreateUser = lazy(() => import('pages/CreateUser'));
const TagsList = lazy(() => import('pages/TagsList'));
const CreateTag = lazy(() => import('pages/CreateTag'));
const TagDetails = lazy(() => import('pages/TagDetails'));
const CampaignsList = lazy(() => import('pages/CampaignsList'));
const CreateCampaign = lazy(() => import('pages/CreateCampaign'));
const CampaignDetails = lazy(() => import('pages/CampaignDetails'));
const WorkflowList = lazy(() => import('pages/WorkflowList'));
const NewsDetailsPage = lazy(() => import('pages/NewsDetails'));
const CreateNewsPage = lazy(() => import('pages/CreateNews'));
const CreateSurvey = lazy(() => import('pages/CreateSurvey'));
const SurveysList = lazy(() => import('pages/SurveysList'));
const SurveyDetails = lazy(() => import('pages/SurveyDetails'));
const AttributesList = lazy(() => import('pages/AttributesList'));
const CreateAttribute = lazy(() => import('pages/CreateAttribute'));
const AttributeDetails = lazy(() => import('pages/AttributeDetails'));
const CreateCategory = lazy(() => import('pages/CreateCategory'));
const LoginPage = lazy(() => import('pages/Login'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'));
const CreatePasswordPage = lazy(() => import('pages/CreatePassword'));
const UserDetailsPage = lazy(() => import('pages/UserDetails'));
const CategoryDetailsPage = lazy(() => import('pages/CategoryDetails'));
const TenantsList = lazy(() => import('pages/TenantsList'));
const CreateTenant = lazy(() => import('pages/CreateTenant'));
const TenantDetails = lazy(() => import('pages/TenantDetails'));

const HealthProfileList = lazy(() => import('pages/HealthProfileList'));
const HealthProfileDetails = lazy(() => import('pages/HealthProfileDetails'));

const HealthAssistantList = lazy(() => import('pages/HealthAssistantList'));
const HealthAssistantDetails = lazy(() => import('pages/HealthAssistantDetails'));

const CreateProductPage = lazy(() => import('pages/CreateProduct'));
const ProductDetailsPage = lazy(() => import('pages/ProductDetails'));
const ProductListPage = lazy(() => import('pages/ProductList'));

const ChartsList = lazy(() => import('pages/ChartsList'));
const CreateChart = lazy(() => import('pages/CreateChart'));
const ChartDetails = lazy(() => import('pages/ChartDetails'));

const AppointmentsList = lazy(() => import('pages/AppointmentsList'));
const CreateAppointment = lazy(() => import('pages/CreateAppointment'));
const AppointmentDetails = lazy(() => import('pages/AppointmentDetails'));

const CubesList = lazy(() => import('pages/CubesList'));
const CreateCube = lazy(() => import('pages/CreateCube'));
const CubeDetails = lazy(() => import('pages/CubeDetails'));

const CategoriesListPage = lazy(() => import('pages/CategoriesList'));

const unauthorizedPaths = {
  '/public-wiki': true,
  '/public-challenges': true,
  '/public-challenge-detail': true,
  '/public-challenge-full-content': true,
  '/simulation-info': true,
  '/simulation-info-detail': true,
  '/simulation-info-full-content': true,
  '/vital-monitor-info': true,
  '/vital-monitor-info-detail': true,
  '/vital-monitor-info-full-content': true,
  '/public-page': true,
};

const authPaths = {
  '/login': true,
  '/create-password': true,
  '/reset-password': true,
  '/forgot-password': true,
};

const checkUnauthorizedPath = (path, pathsToCheck) => {
  return Object.keys(pathsToCheck).reduce((acc, el) => {
    if (path.includes(el)) return true;
    return acc;
  }, false);
};

const AppMain = (props) => {
  window.scrollTo(0, 0);
  useEffect(() => {
    const matchesUnauthPath = checkUnauthorizedPath(props.location.pathname, unauthorizedPaths);
    const matchesAuthPath = checkUnauthorizedPath(props.location.pathname, authPaths);
    if (matchesUnauthPath) {
      window.noLoginRedirect = true;
      props.checkAuth();
    } else if (!matchesAuthPath) {
      props.checkAuth();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.userRole) {
      props.getProductConstants();
    }
  }, [props.userRole]);

  const isPublicPage = props.location.pathname.includes('public-page');

  if (checkUnauthorizedPath(props.location.pathname, authPaths)) {
    return (
      <Suspense
        fallback={
          <div className='loader-container'>
            <div className='loader-container-inner'>
              <div className='text-center'>
                <Loader type='ball-grid-beat' />
              </div>
              <h6 className='mt-3'>Loading...</h6>
            </div>
          </div>
        }
      >
        <Route path='/login' component={LoginPage} />
        <Route path='/forgot-password' component={ForgotPasswordPage} />
        <Route path='/reset-password' component={ResetPasswordPage} />
        <Route path='/create-password' component={CreatePasswordPage} />
        <ToastContainer />
        <Notification />
      </Suspense>
    );
  }

  if (!props.userRole)
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component='div'
          transitionName='TabsAnimation'
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Fragment>
            <div className='d-container'>
              <div className='d-sidebar-base'></div>
              <AppSidebar darkTheme={props.darkTheme} />
              <div className='d-content'>
                <AppHeader noTabs />
                <div className='app-main__outer'>
                  <div className='app-main__inner app-layout-main'>
                    <div
                      className={`app-main__layout
                        ${isPublicPage ? 'pub-page__layout' : ''}`}
                      style={{
                        background: isPublicPage ? 'rgba(235, 237, 237, 0.4)' : '#fff',
                      }}
                    >
                      <Suspense
                        fallback={
                          <div className='loader-container'>
                            <div className='loader-container-inner'>
                              <div className='text-center'>
                                <Loader type='ball-grid-beat' />
                              </div>
                              <h6 className='mt-3'>Loading...</h6>
                            </div>
                          </div>
                        }
                      ></Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </ReactCSSTransitionGroup>
        <AppFooter />
      </Fragment>
    );

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component='div'
        transitionName='TabsAnimation'
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Fragment>
          <div
            className={`d-container ${
              (props.userRole === 'admin' || props.userRole === 'creator') && !isPublicPage ? 'admin-container' : ''
            }`}
          >
            <div className='d-sidebar-base'></div>
            <AppSidebar darkTheme={props.darkTheme} />
            <div className='d-content'>
              <AppHeader noTabs />
              <div className='app-main__outer'>
                <div
                  className={
                    props.userRole === 'user' || isPublicPage ? 'app-main__inner app-layout-main' : 'app-main__inner'
                  }
                >
                  <div
                    className={`${props.userRole === 'user' || isPublicPage ? 'app-main__layout' : ''}
                      ${props.history.location.pathname === '/simulation' ? 'sim-layout-wrapper' : ''}
                      ${isPublicPage ? 'pub-page__layout' : ''}`}
                  >
                    <Suspense
                      fallback={
                        <div className='loader-container'>
                          <div className='loader-container-inner'>
                            <div className='text-center'>
                              <Loader type='ball-grid-beat' />
                            </div>
                            <h6 className='mt-3'>Loading...</h6>
                          </div>
                        </div>
                      }
                    >
                      {props.userRole !== 'user' && (
                        <>
                          <Route exact path='/create-news' component={CreateNewsPage} />
                          <Route exact path='/workflow' component={WorkflowList} />
                          <Route exact path='/tags' component={TagsList} />
                          <Route exact path='/edit-tag/:id' component={TagDetails} />
                          <Route exact path='/view-tag/:id' component={TagDetails} />
                          <Route exact path='/create-tag' component={CreateTag} />
                          <Route exact path='/users' component={UserListPage} />
                          <Route exact path='/news' component={NewsPage} />
                          <Route exact path='/products' component={ProductListPage} />
                          <Route exact path='/create-product' component={CreateProductPage} />
                          <Route exact path='/surveys' component={SurveysList} />
                          <Route exact path='/create-survey' component={CreateSurvey} />
                          <Route exact path='/view-survey/:id' component={SurveyDetails} />
                          <Route exact path='/edit-survey/:id' component={SurveyDetails} />
                          <Route exact path='/view-news/:id' component={NewsDetailsPage} />
                          <Route exact path='/view-news/:id/:versionId' component={NewsDetailsPage} />
                          <Route exact path='/edit-news/:id/:versionId' component={NewsDetailsPage} />
                          <Route exact path='/edit-news/:id' component={NewsDetailsPage} />
                          <Route exact path='/view-product/:id' component={ProductDetailsPage} />
                          <Route exact path='/edit-product/:id' component={ProductDetailsPage} />
                          <Route exact path='/attributes' component={AttributesList} />
                          <Route exact path='/create-attribute' component={CreateAttribute} />
                          <Route exact path='/update-attribute/:id' component={AttributeDetails} />
                          <Route exact path='/view-attribute/:id' component={AttributeDetails} />
                          <Route exact path='/edit-user/:id' component={UserDetailsPage} />
                          <Route exact path='/view-user/:id' component={UserDetailsPage} />
                          <Route exact path='/create-user' component={CreateUser} />
                          <Route exact path='/create-category' component={CreateCategory} />
                          <Route exact path='/categories' component={CategoriesListPage} />
                          <Route exact path='/edit-category/:id' component={CategoryDetailsPage} />
                          <Route exact path='/view-category/:id' component={CategoryDetailsPage} />
                          <Route exact path='/tenants' component={TenantsList} />
                          <Route exact path='/create-tenant' component={CreateTenant} />
                          <Route exact path='/edit-tenant/:id' component={TenantDetails} />
                          <Route exact path='/view-tenant/:id' component={TenantDetails} />
                          <Route exact path='/campaigns' component={CampaignsList} />
                          <Route exact path='/edit-campaign/:id' component={CampaignDetails} />
                          <Route exact path='/view-campaign/:id' component={CampaignDetails} />
                          <Route exact path='/view-campaign/:id/:version' component={CampaignDetails} />
                          <Route exact path='/edit-campaign/:id/:version' component={CampaignDetails} />
                          <Route exact path='/view-campaign' component={CampaignDetails} />
                          <Route exact path='/create-campaign' component={CreateCampaign} />
                          <Route exact path='/charts' component={ChartsList} />
                          <Route exact path='/create-chart' component={CreateChart} />
                          <Route exact path='/update-chart/:id' component={ChartDetails} />
                          <Route exact path='/view-chart/:id' component={ChartDetails} />
                          <Route exact path='/appointments' component={AppointmentsList} />
                          <Route exact path='/create-appointment' component={CreateAppointment} />
                          <Route exact path='/update-appointment/:id' component={AppointmentDetails} />
                          <Route exact path='/view-appointment/:id' component={AppointmentDetails} />
                          <Route exact path='/cubes' component={CubesList} />
                          <Route exact path='/create-cube' component={CreateCube} />
                          <Route exact path='/update-cube/:id' component={CubeDetails} />
                          <Route exact path='/view-cube/:id' component={CubeDetails} />
                          <Route exact path='/health-profiles' component={HealthProfileList} />
                          <Route exact path='/health-profiles/:id' component={HealthProfileDetails} />
                          <Route exact path='/health-assistants' component={HealthAssistantList} />
                          <Route exact path='/health-assistants/:id' component={HealthAssistantDetails} />
                        </>
                      )}

                      {props.userRole && props.userRole !== 'user' && (
                        <Route exact path='/' render={() => <Redirect to='/workflow' />} />
                      )}
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </ReactCSSTransitionGroup>

      <ToastContainer />
      <Notification />
      {<Loading loading={props.loading} />}
      <AppFooter />
    </Fragment>
  );
};

const mapStateToProps = ({ UserRoles, Loading, Theme }) => ({
  userRole: UserRoles.userRole,
  loading: Loading.loading,
  darkTheme: Theme.darkTheme,
});

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth.request()),
  getDocuments: () => dispatch(getDocuments.request()),
  setDarkTheme: (payload) => dispatch({ type: 'SET_THEME', payload }),
  setLoading: (data) => dispatch(setLoading(data)),
  getProductConstants: () => dispatch(getProductConstants.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppMain));
