import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import {Router, Route} from "react-router-dom";
import history from "./utils/history";
import "./assets/base.scss";
import configureStore from "./config/configureStore";
import "react-datepicker/dist/react-datepicker.css";
import {Provider} from "react-redux";
import Main from "./layout/Main";
import rootSaga from "./sagas";
import ClearCache from "react-clear-cache";

const store = configureStore();
const rootElement = document.getElementById("root");

store.runSaga(rootSaga);

const renderApp = Component => {
  ReactDOM.render(
    <ClearCache auto>
      {({isLatestVersion, emptyCacheStorage}) => (
        <Provider store={store}>
          <Router history={history}>
            <Route
              component={Component}
            />
          </Router>
        </Provider>
      )}
    </ClearCache>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./layout/Main", () => {
    const NextApp = require("./layout/Main").default;
    renderApp(NextApp);
  });
}

serviceWorker.unregister();
