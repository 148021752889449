import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_HEALTH_ASSISTANTS = createRequestTypes('GET_HEALTH_ASSISTANTS');
export const GET_HEALTH_ASSISTANT = createRequestTypes('GET_HEALTH_ASSISTANT');

export const getHealthAssistants = {
  request: () => action(GET_HEALTH_ASSISTANTS[REQUEST]),
  success: (payload) => action(GET_HEALTH_ASSISTANTS[SUCCESS], { payload }),
  failure: (payload) => action(GET_HEALTH_ASSISTANTS[FAILURE], { payload }),
};

export const getHealthAssistant = {
  request: (id) => action(GET_HEALTH_ASSISTANT[REQUEST], {id}),
  success: (payload) => action(GET_HEALTH_ASSISTANT[SUCCESS], { payload }),
  failure: (payload) => action(GET_HEALTH_ASSISTANT[FAILURE], { payload }),
}
