import { APPOINTMENT_TYPE_ANCHOR, APPOINTMENT_TYPE_BOOKING } from 'components/AppointmentForm/constants'

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const defaultLocale = 'de';

export const ALLERGIES_OR_INTOLERANCES = {
  gluten: 'Gluten',
  crustaceans: 'Krebstiere',
  egg: 'Ei',
  fish: 'Fisch',
  peanut: 'Erdnuss',
  soy: 'Soja',
  milk: 'Milch',
  nuts: 'Schalenfrüchte',
  celery: 'Sellerie',
  mustard: 'Senf',
  sesame: 'Sesam',
  sulfites: 'Sulfite',
  lupine: 'Hülsenfrüchte',
  molluscs: 'Weichtiere',
};

export const USAGE_OPTIONS = [
  { value: 'BOARD', label: 'BOARD' },
  { value: 'FILTER', label: 'FILTER' },
];

export const productFrequencyMap = {
  daily: 'Täglich',
  MON: 'Montag',
  TUE: 'Dienstag',
  WED: 'Mittwoch',
  THU: 'Donnerstag',
  FRI: 'Freitag',
  SAT: 'Samstag',
  SUN: 'Sonntag',
};

export const productFormOptions = [
  {
    value: 'capsule',
    label: 'Kapseln',
  },
  {
    value: 'tablet',
    label: 'Tabletten',
  },
  {
    value: 'drops',
    label: 'Tropfen',
  },
  {
    value: 'powder',
    label: 'Pulver',
  },
];

export const totalAmountUnitValuesMap = {
  capsule: {
    de: 'Kapsel',
    en: 'Capsule',
  },
  tablet: {
    de: 'Tablette',
    en: 'Tablet',
  },
  drop: {
    de: 'Tropfen',
    en: 'Drop',
  },
  ml: {
    de: 'ml',
    en: 'ml',
  },
  mg: {
    de: 'mg',
    en: 'mg',
  },
  g: {
    de: 'gramm',
    en: 'gram',
  },
};

export const QUESTIONNAIRE_TEXT = {
  1: {
    title: 'i will.',
    text: 'Nimm deine Gesundheit jetzt in die Hand.',
  },
  2: {
    title: 'i check.',
    text: 'Deine Angaben werden wissenschaftlich ausgewertet.',
  },
  3: {
    title: 'i live.',
    text: 'Deine Gesundheitsthemen.',
  },
  4: {
    title: 'i try.',
    text: 'Deine Gewohnheiten und dein Lebensstil.',
  },
  5: {
    title: 'i eat.',
    text: 'Deine Ernährung',
  },
  6: {
    title: 'i work.',
    text: 'Zusatzfragen',
  },
  7: {
    title: 'i relax. ',
    text: 'Nimm deine Gesundheit jetzt in die Hand.',
  },
};

export const categories = [
  'Alles',
  'Schlaf & Entspannung verbessern',
  'Wohlbefinden & Energie',
  'Gewichtsmanagement verbessern',
  'Immunsystem unterstützen',
  'Konzentration verbessern',
  'Darmgesundheit optimieren',
];

export const categoryOptions = categories.map((el) => ({
  value: el,
  label: el,
}));

export const entryTypes = {
  productIntake: 'user_product_intake_schedule',
  reminder: 'reminder',
  anchorAppointment: APPOINTMENT_TYPE_ANCHOR, 
  bookingAppointment: APPOINTMENT_TYPE_BOOKING,
};

export const workflowStatuses = [
  { value: 'progress', label: 'In Arbeit' },
  { value: 'editorial_review', label: 'Inhaltprüfung' },
  { value: 'content_review', label: 'Zur Freigabe' },
  { value: 'released', label: 'Freigegeben' },
];

// alphanum, number, floating, bool, date

export const ATTRIBUTE_TYPES = [
  'alphanum',
  'number',
  'floating',
  'bool',
  'datetime',
];

export const BOOL_ATTRIBUTE_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];
