import { takeEvery } from "redux-saga/effects";
import { apiRequest } from "./index";
import api from "utils/api";
import * as attributesActions from "actions/Attributes";
import * as userActions from "actions/User";
import history from "utils/history";
import {get} from "lodash";
import {Slide, toast} from "react-toastify";

const getAttributes = apiRequest.bind(null, attributesActions.getAttributes, api.getAttributes);
const getAttribute = apiRequest.bind(null, attributesActions.getAttribute, api.getAttribute);
const getUserAttributeHistory = apiRequest.bind(null, attributesActions.getUserAttributeHistory, api.getUserAttributeHistory);
const createAttribute = apiRequest.bind(
  null,
  attributesActions.createAttribute,
  api.createAttribute
);
const updateAttribute = apiRequest.bind(
  null,
  attributesActions.updateAttribute,
  api.updateAttribute
);
const deleteAttribute = apiRequest.bind(
  null,
  attributesActions.deleteAttribute,
  api.deleteAttribute
);

const addAttributeToUser = apiRequest.bind(
  null,
  attributesActions.addAttributeToUser,
  api.addAttributeToUser
);

const deleteAttributeFromUser = apiRequest.bind(
  null,
  attributesActions.deleteAttributeFromUser,
  api.deleteAttributeFromUser
);

const getUserAttributes = apiRequest.bind(
  null,
  attributesActions.getUserAttributes,
  api.getUserAttributes
);

function* handleChangeAttributeSuccess(action) {
  if (get(action.payload, 'options') === "redirect") {
    return yield history.push("/attributes");
  } else {
    yield history.replace(`/update-attribute/${action.payload.id}`)
    const arg = {id: action.payload.id}
    yield apiRequest.apply(null, [attributesActions.getAttribute, api.getAttribute, arg])

    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type: 'success'
    })
  }
}

function* handleChangeUserAttributeSuccess(action) {
  yield toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: "bottom-center",
    type: 'success'
  })

  if (action.payload.isHistory) {
    const arg = {data: {userUuid: action.payload.keycloakUuid, attributeId: action.payload.attributes[0].attributeDefinitionId}}
    yield apiRequest.apply(null, [attributesActions.getUserAttributeHistory, api.getUserAttributeHistory, arg]);
  }

  const arg = {id: action.payload.id}
  yield apiRequest.apply(null, [userActions.getUser, api.getUser, arg]);
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetAttributesRequest() {
  yield takeEvery(attributesActions.GET_ATTRIBUTES.REQUEST, getAttributes);
}

export function* watchGetAttributeRequest() {
  yield takeEvery(attributesActions.GET_ATTRIBUTE.REQUEST, getAttribute);
}

export function* watchCreateAttributeRequest() {
  yield takeEvery(attributesActions.CREATE_ATTRIBUTE.REQUEST, createAttribute);
}

export function* watchUpdateAttributeRequest() {
  yield takeEvery(attributesActions.UPDATE_ATTRIBUTE.REQUEST, updateAttribute);
}

export function* watchDeleteAttributeRequest() {
  yield takeEvery(attributesActions.DELETE_ATTRIBUTE.REQUEST, deleteAttribute);
}

export function* watchDeleteAttributeSuccess() {
  yield takeEvery(attributesActions.DELETE_ATTRIBUTE.SUCCESS, getAttributes);
}

export function* watchCreateAttributeSuccess() {
  yield takeEvery(attributesActions.CREATE_ATTRIBUTE.SUCCESS, handleChangeAttributeSuccess);
}

export function* watchUpdateAttributeSuccess() {
  yield takeEvery(attributesActions.UPDATE_ATTRIBUTE.SUCCESS, handleChangeAttributeSuccess);
}

export function* watchAddAttributeToUserRequest() {
  yield takeEvery(attributesActions.ADD_ATTRIBUTE_TO_USER.REQUEST, addAttributeToUser);
}

export function* watchAddAttributeToUserSuccess() {
  yield takeEvery(attributesActions.ADD_ATTRIBUTE_TO_USER.SUCCESS, handleChangeUserAttributeSuccess);
}

export function* watchDeleteAttributeToUserRequest() {
  yield takeEvery(attributesActions.DELETE_ATTRIBUTE_FROM_USER.REQUEST, deleteAttributeFromUser);
}

export function* watchDeleteAttributeToUserSuccess() {
  yield takeEvery(attributesActions.DELETE_ATTRIBUTE_FROM_USER.SUCCESS, handleChangeUserAttributeSuccess);
}

export function* watchGetUserAttributes() {
  yield takeEvery(attributesActions.GET_USER_ATTRIBUTES.REQUEST, getUserAttributes);
}

export function* watchGetUserAttributeHistory() {
  yield takeEvery(attributesActions.GET_USER_ATTRIBUTE_HISTORY.REQUEST, getUserAttributeHistory);
}


