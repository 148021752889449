import * as ActionTypes from 'actions/HealthAssistant';

const initialState = {
  assistants: [],
  assistant: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_HEALTH_ASSISTANTS.SUCCESS:
      return {
        ...state,
        assistants: action.payload,
      };
    case ActionTypes.GET_HEALTH_ASSISTANT.SUCCESS:
      return {
        ...state,
        assistant: action.payload
      }
    default:
      return state;
  }
}
