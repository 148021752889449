import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import ResizeDetector from "react-resize-detector";

import AppMain from "../AppMain";

class Main extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-130716105-2");
  }

  render() {
    let { darkTheme } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx("app-container app-theme-gray", {
                "dark-theme": darkTheme
              })}
            >
              <AppMain />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = ({ Theme }) => ({
  darkTheme: Theme.darkTheme
});

export default withRouter(connect(mapStateToProp)(Main));
