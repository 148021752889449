import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import MetisMenu from "react-metismenu";
import {connect} from "react-redux";

import {navItemsByRole} from "./NavItems";
import NavItem from "./NavItem";

// const getNav = userRole => {
//   if (userRole === "user") return null;
//   if (userRole === "admin") return AdminNavItems;
//   return EditorNavItems;
// };

class Nav extends Component {
  navigateTo = path => {
    const { history } = this.props;
    history.push(`/${path}`);
  };

  getNav = userRole => {
    return navItemsByRole[userRole] || [];
  };
  render() {
    const {
      userRole,
      location: { pathname }
    } = this.props;

    const nav = this.getNav(userRole);

    if (!nav?.length) return null;
    return (
      <div className={userRole !== "user" ? "non-user-sidebar" : ""}>
        {(userRole === "admin" || userRole === "tenant-admin" || userRole === "reviewer" || userRole === "editor" || userRole === "approver") ? (
          <>
            <MetisMenu
              content={nav}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        ) : (
          <>
            <NavItem
              currentPath={pathname}
              path="dashboard"
              handlePress={this.navigateTo}
              title="VITAL Monitor"
              icon="nav_1"
            />
            <NavItem
              currentPath={pathname}
              path="simulation"
              handlePress={this.navigateTo}
              title="Neue SIM starten"
              icon="nav_2"
            />
            <NavItem
              currentPath={pathname}
              path="challenge"
              handlePress={this.navigateTo}
              title="Challenges"
              icon="nav_3"
            />
            <NavItem
              currentPath={pathname}
              link="https://shop.iqest.com"
              title="Shop"
              icon="nav_4"
            />

            <div style={{ height: "106px" }}></div>
            {/*<NavItem
              currentPath={pathname}
              path="profile"
              handlePress={this.navigateTo}
              title="Profil"
              icon="nav_5"
            />
            <NavItem
              currentPath={pathname}
              path="settings"
              handlePress={this.navigateTo}
              title="Einstellungen"
              icon="nav_6"
            />*/}
          </>
        )}
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

const mapStateToProps = ({ UserRoles }) => ({
  userRole: UserRoles.userRole
});

export default connect(
  mapStateToProps,
  null
)(withRouter(Nav));
