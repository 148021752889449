import { select, takeEvery } from 'redux-saga/effects';
import {Slide, toast} from "react-toastify";
import * as campaignsActions from 'actions/Campaigns';
import api from '../utils/api';
import { apiRequest } from './index';
import { get } from 'lodash';
import history from "../utils/history";

const getCampaigns = apiRequest.bind(null, campaignsActions.getCampaigns, api.getCampaigns)
const getCampaign = apiRequest.bind(null, campaignsActions.getCampaign, api.getCampaign)
const startCampaign = apiRequest.bind(null, campaignsActions.startCampaign, api.startCampaign)
const getUserCampaigns = apiRequest.bind(null, campaignsActions.getUserCampaigns, api.getUserCampaigns)
const getUserArchivedCampaigns = apiRequest.bind(null, campaignsActions.getUserArchivedCampaigns, api.getUserArchivedCampaigns)
const getCampaignByVersion = apiRequest.bind(null, campaignsActions.getCampaignByVersion, api.getCampaignByVersion)
const createCampaign = apiRequest.bind(null, campaignsActions.createCampaign, api.createCampaign)
const updateCampaign = apiRequest.bind(null, campaignsActions.updateCampaign, api.updateCampaign)

function* refreshCampaigns() {
  // const state = yield select();
  // const commentsUserId = get(state, 'Campaigns.commentsUserId');
  // const arg = {
  //   id: commentsUserId
  // }
  // yield apiRequest.apply(null, [campaignsActions.getCampaigns, api.getCampaigns, arg])
}

function* handleCreateCampaignSuccess(action) {
  if (get(action.payload, 'options') === "redirect") {
    return yield history.push("/campaigns");
  } else {
    yield history.replace(`/edit-campaign/${action.payload.templateId}`);
    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type: 'success'
    })
    const arg = {id: action.payload.templateId}
    yield apiRequest.apply(null, [campaignsActions.getCampaign, api.getCampaign, arg])
  }
}

function* handleStartCampaignSuccess(action) {
  const state = yield select();

  const arg = {data: {
      userUuid: get(state, "User.user.keycloakUuid", 0),
    }}

  // console.log(action)
  yield apiRequest.apply(null, [campaignsActions.getUserCampaigns, api.getUserCampaigns, arg])

  yield toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: "bottom-center",
    type: 'success'
  })
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetCampaignsRequest() {
  yield takeEvery(campaignsActions.GET_CAMPAIGNS.REQUEST, getCampaigns)
}

export function* watchGetCampaignRequest() {
  yield takeEvery(campaignsActions.GET_CAMPAIGN.REQUEST, getCampaign)
}

export function* watchStartCampaignRequest() {
  yield takeEvery(campaignsActions.START_CAMPAIGN.REQUEST, startCampaign)
}

export function* watchStartCampaignSuccess() {
  yield takeEvery(campaignsActions.START_CAMPAIGN.SUCCESS, handleStartCampaignSuccess)
}

export function* watchGetCampaignByVersionRequest() {
  yield takeEvery(campaignsActions.GET_CAMPAIGN_BY_VERSION.REQUEST, getCampaignByVersion)
}

export function* watchCreateCampaignRequest() {
  yield takeEvery(campaignsActions.CREATE_CAMPAIGN.REQUEST, createCampaign)
}

export function* watchCreateCampaignSuccess() {
  yield takeEvery(campaignsActions.CREATE_CAMPAIGN.SUCCESS, handleCreateCampaignSuccess)
}

export function* watchUpdateCampaign() {
  yield takeEvery(campaignsActions.UPDATE_CAMPAIGN.REQUEST, updateCampaign)
}

export function* watchAddCampaignSuccess() {
  yield takeEvery(campaignsActions.CREATE_CAMPAIGN.SUCCESS, refreshCampaigns)
}

export function* watchUpdateCampaignSuccess() {
  yield takeEvery(campaignsActions.UPDATE_CAMPAIGN.SUCCESS, handleCreateCampaignSuccess)
}

export function* watchGetUserCampaignsRequest() {
  yield takeEvery(campaignsActions.GET_USER_CAMPAIGNS.REQUEST, getUserCampaigns)
}

export function* watchGetUserArchivedCampaignsRequest() {
  yield takeEvery(campaignsActions.GET_USER_ARCHIVED_CAMPAIGNS.REQUEST, getUserArchivedCampaigns)
}