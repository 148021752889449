import * as ActionTypes from 'actions/News';
import { get } from 'lodash';

const initialState = {
  newsList: [],
  newsTemplates: [],
  news: null,
  userFeed: [],
  selectedNews: [],
  newsCategories: [],
  newsWorkflowList: [],
  newsTableFilter: {},
  newsTableSorting: {},
  newsTab: '0',
  workflowTableFilter: {},
  userFeedSorting: {},
  workflowTableSorting: {},
  workflowHistory: [],
  selectedNewsPdf: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_NEWS_LIST.SUCCESS:
      return {
        ...state,
        newsList: get(action, 'payload.content', []),
        newsListPage: get(action, 'payload.currentPage', 0),
        newsListPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_NEWS_TEMPLATES.SUCCESS:
      return {
        ...state,
        newsTemplates: get(action, 'payload', []),
      };
    case ActionTypes.GET_WORKFLOW_LIST.SUCCESS:
      return {
        ...state,
        newsWorkflowList: get(action, 'payload.content', []),
        newsWorkflowListPage: get(action, 'payload.currentPage', 0),
        newsWorkflowListPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_NEWS.SUCCESS:
      return {
        ...state,
        news: action.payload?.news,
        availableActions: action.payload?.actions,
      };
    case ActionTypes.GET_NEWS_BY_VERSION.SUCCESS:
      return {
        ...state,
        news: action.payload,
      };
    case ActionTypes.SELECT_NEWS:
      return {
        ...state,
        selectedNews: action.payload,
      };
    case ActionTypes.GET_USER_FEED.SUCCESS:
      return {
        ...state,
        userFeed: get(action, 'payload.content', []),
        userFeedPage: get(action, 'payload.currentPage', 0),
        userFeedPages: get(action, 'payload.totalPages', 1),
      };
    case ActionTypes.GET_WORKFLOW_HISTORY.SUCCESS:
      return {
        ...state,
        workflowHistory: get(action, 'payload.workflowSteps', []),
      };
    case ActionTypes.SET_NEWS_TABLE_FILTER:
      return { ...state, newsTableFilter: action.payload };
    case ActionTypes.SET_USER_PRODUCT_FILTER:
      return { ...state, userProductFilter: action.payload };
    case ActionTypes.SET_USER_FEED_FILTER:
      return { ...state, userFeedFilter: action.payload };
    case ActionTypes.SET_USER_FEED_SORTING:
      return { ...state, userFeedSorting: action.payload };
    case ActionTypes.SET_WORKFLOW_TABLE_FILTER:
      return { ...state, workflowTableFilter: action.payload };
    case ActionTypes.SET_WORKFLOW_TABLE_SORTING:
      return { ...state, workflowTableSorting: action.payload };
    case ActionTypes.SET_NEWS_TABLE_SORTING:
      return { ...state, newsTableSorting: action.payload };
    case ActionTypes.SET_NEWS_TAB:
      return { ...state, newsTab: action.payload };
    default:
      return state;
  }
}
