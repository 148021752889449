import { all, takeEvery, put } from 'redux-saga/effects';
import { apiRequest } from './index';
import AppointmentsApi from '../api/Appointments';
import * as appointmentsActions from 'actions/Appointments';
import { Slide, toast } from 'react-toastify';
import history from 'utils/history';

const getAppointments = apiRequest.bind(null, appointmentsActions.getAppointments, AppointmentsApi.getAppointments);
const getAppointment = apiRequest.bind(null, appointmentsActions.getAppointment, AppointmentsApi.getAppointment);
const deleteAppointment = apiRequest.bind(
  null,
  appointmentsActions.deleteAppointment,
  AppointmentsApi.deleteAppointment
);

function* updateAppointment({id, data, options}) {
  const { response } = yield AppointmentsApi.updateAppointment(id, data)
  yield put({
    type: appointmentsActions.UPDATE_APPOINTMENT.SUCCESS,
    payload: response
  });

  if (options === 'redirect') {
    yield history.push('/appointments')
  }
}

function* createAppointment({data, options}) {
  const { response } = yield AppointmentsApi.createAppointment(data)
  yield put({
    type: appointmentsActions.CREATE_APPOINTMENT.SUCCESS,
    payload: response
  });

  if (options === 'redirect') {
    yield history.push('/appointments')
  } else {
    yield history.replace(`/update-appointment/${response.id}`)
  }
}


const appointmentSaveHandler = () => {
  toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: 'bottom-center',
    type: 'success',
  });
};

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* rootWatcherAppointments() {
  yield all([
    takeEvery(appointmentsActions.GET_APPOINTMENTS.REQUEST, getAppointments),
    takeEvery(appointmentsActions.CREATE_APPOINTMENT.REQUEST, createAppointment),
    takeEvery(appointmentsActions.CREATE_APPOINTMENT.SUCCESS, appointmentSaveHandler),
    takeEvery(appointmentsActions.GET_APPOINTMENT.REQUEST, getAppointment),
    takeEvery(appointmentsActions.UPDATE_APPOINTMENT.REQUEST, updateAppointment),
    takeEvery(appointmentsActions.UPDATE_APPOINTMENT.SUCCESS, appointmentSaveHandler),
    takeEvery(appointmentsActions.DELETE_APPOINTMENT.REQUEST, deleteAppointment),
  ]);
}
