import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_NEWS_LIST = createRequestTypes('GET_NEWS_LIST');
export const GET_NEWS_TEMPLATES = createRequestTypes('GET_NEWS_TEMPLATES');
export const GET_NEWS = createRequestTypes('GET_NEWS');
export const CREATE_NEWS = createRequestTypes('CREATE_NEWS');
export const UPDATE_NEWS = createRequestTypes('UPDATE_NEWS');
export const DELETE_NEWS = createRequestTypes('DELETE_NEWS');
export const DELETE_NEWS_LIST = createRequestTypes('DELETE_NEWS_LIST');
export const GET_NEWS_BY_VERSION = createRequestTypes('GET_NEWS_BY_VERSION');
export const SEND_DIRECT_PUSH = createRequestTypes('SEND_DIRECT_PUSH');
export const SEND_DIRECT_PUSH_TEMPLATE = createRequestTypes(
  'SEND_DIRECT_PUSH_TEMPLATE'
);
export const COPY_NEWS = createRequestTypes('COPY_NEWS');
export const GET_USER_FEED = createRequestTypes('GET_USER_FEED');
export const ADD_GROUPS_TO_NEWS = createRequestTypes('ADD_GROUPS_TO_NEWS');
export const SET_PUBLICATION_DATE = createRequestTypes('SET_PUBLICATION_DATE');
export const CANCEL_PUBLICATION = createRequestTypes('CANCEL_PUBLICATION');
export const UPDATE_NEWS_PARTIALLY = createRequestTypes(
  'UPDATE_NEWS_PARTIALLY'
);
export const DOWNLOAD_PDF_NEWS = createRequestTypes('DOWNLOAD_PDF_NEWS');

//workflow
export const GET_USER_WORKFLOW = createRequestTypes('GET_USER_WORKFLOW');
export const GET_WORKFLOW_LIST = createRequestTypes('GET_WORKFLOW_LIST');
export const APPROVE_NEWS = createRequestTypes('APPROVE_NEWS');
export const REJECT_NEWS = createRequestTypes('REJECT_NEWS');
export const PICKUP_NEWS = createRequestTypes('PICKUP_NEWS');
export const UPDATE_LATEST_VERSION = createRequestTypes(
  'UPDATE_LATEST_VERSION'
);
export const CREATE_NEW_VERSION = createRequestTypes('CREATE_NEW_VERSION');
export const GET_WORKFLOW_HISTORY = createRequestTypes('GET_WORKFLOW_HISTORY');

//categories
export const SET_NEWS_CATEGORIES = createRequestTypes('SET_NEWS_CATEGORIES');
export const SET_NEWS_TAGS = createRequestTypes('SET_NEWS_TAGS');

export const SELECT_NEWS = 'SELECT_NEWS';
export const SET_NEWS_TAB = 'SET_NEWS_TAB';
export const SET_USER_PRODUCT_FILTER = 'SET_USER_PRODUCT_FILTER';
export const SET_NEWS_TABLE_FILTER = 'SET_NEWS_TABLE_FILTER';
export const SET_USER_FEED_FILTER = 'SET_USER_FEED_FILTER';
export const SET_USER_FEED_SORTING = 'SET_USER_FEED_SORTING';
export const SET_WORKFLOW_TABLE_FILTER = 'SET_WORKFLOW_TABLE_FILTER';
export const SET_WORKFLOW_TABLE_SORTING = 'SET_WORKFLOW_TABLE_SORTING';
export const SET_NEWS_TABLE_SORTING = 'SET_NEWS_TABLE_SORTING';

export const getNewsList = {
  request: (data, meta) => action(GET_NEWS_LIST[REQUEST], { data, meta }),
  success: (payload) => action(GET_NEWS_LIST[SUCCESS], { payload }),
  failure: (payload) => action(GET_NEWS_LIST[FAILURE], { payload }),
};

export const getWorkflowList = {
  request: (data) => action(GET_WORKFLOW_LIST[REQUEST], { data }),
  success: (payload) => action(GET_WORKFLOW_LIST[SUCCESS], { payload }),
  failure: (payload) => action(GET_WORKFLOW_LIST[FAILURE], { payload }),
};

export const getNewsTemplates = {
  request: () => action(GET_NEWS_TEMPLATES[REQUEST]),
  success: (payload) => action(GET_NEWS_TEMPLATES[SUCCESS], { payload }),
  failure: (payload) => action(GET_NEWS_TEMPLATES[FAILURE], { payload }),
};

export const getNews = {
  request: (id, meta) => action(GET_NEWS[REQUEST], { id, meta }),
  success: (payload) => action(GET_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(GET_NEWS[FAILURE], { payload }),
};

export const getNewsByVersion = {
  request: (data) => action(GET_NEWS_BY_VERSION[REQUEST], { data }),
  success: (payload) => action(GET_NEWS_BY_VERSION[SUCCESS], { payload }),
  failure: (payload) => action(GET_NEWS_BY_VERSION[FAILURE], { payload }),
};

export const createNews = {
  request: (data) => action(CREATE_NEWS[REQUEST], { data }),
  success: (payload) => action(CREATE_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_NEWS[FAILURE], { payload }),
};

export const updateNews = {
  request: (id, data) => action(UPDATE_NEWS[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_NEWS[FAILURE], { payload }),
};

export const deleteNews = {
  request: (id) => action(DELETE_NEWS[REQUEST], { id }),
  success: (payload) => action(DELETE_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_NEWS[FAILURE], { payload }),
};

export const deleteNewsList = {
  request: (data) => action(DELETE_NEWS_LIST[REQUEST], { data }),
  success: (payload) => action(DELETE_NEWS_LIST[SUCCESS], { payload }),
  failure: (payload) => action(DELETE_NEWS_LIST[FAILURE], { payload }),
};

export const copyNews = {
  request: (id) => action(COPY_NEWS[REQUEST], { id }),
  success: (payload) => action(COPY_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(COPY_NEWS[FAILURE], { payload }),
};

export const sendDirectPush = {
  request: (data) => action(SEND_DIRECT_PUSH[REQUEST], { data }),
  success: (payload) => action(SEND_DIRECT_PUSH[SUCCESS], { payload }),
  failure: (payload) => action(SEND_DIRECT_PUSH[FAILURE], { payload }),
};

export const sendDirectPushTemplate = {
  request: (data) => action(SEND_DIRECT_PUSH_TEMPLATE[REQUEST], { data }),
  success: (payload) => action(SEND_DIRECT_PUSH_TEMPLATE[SUCCESS], { payload }),
  failure: (payload) => action(SEND_DIRECT_PUSH_TEMPLATE[FAILURE], { payload }),
};

export const setNewsCategories = {
  request: (id, data) => action(SET_NEWS_CATEGORIES[REQUEST], { id, data }),
  success: (payload) => action(SET_NEWS_CATEGORIES[SUCCESS], { payload }),
  failure: (payload) => action(SET_NEWS_CATEGORIES[FAILURE], { payload }),
};

export const setNewsTags = {
  request: (id, data) => action(SET_NEWS_TAGS[REQUEST], { id, data }),
  success: (payload) => action(SET_NEWS_TAGS[SUCCESS], { payload }),
  failure: (payload) => action(SET_NEWS_TAGS[FAILURE], { payload }),
};

export const getUserFeed = {
  request: (data) => action(GET_USER_FEED[REQUEST], { data }),
  success: (payload) => action(GET_USER_FEED[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_FEED[FAILURE], { payload }),
};

export const getUserWorkflow = {
  request: (data) => action(GET_USER_WORKFLOW[REQUEST], { data }),
  success: (payload) => action(GET_USER_WORKFLOW[SUCCESS], { payload }),
  failure: (payload) => action(GET_USER_WORKFLOW[FAILURE], { payload }),
};

export const approveNews = {
  request: (id) => action(APPROVE_NEWS[REQUEST], { id }),
  success: (payload) => action(APPROVE_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(APPROVE_NEWS[FAILURE], { payload }),
};

export const rejectNews = {
  request: (id) => action(REJECT_NEWS[REQUEST], { id }),
  success: (payload) => action(REJECT_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(REJECT_NEWS[FAILURE], { payload }),
};

export const pickupNews = {
  request: (data) => action(PICKUP_NEWS[REQUEST], { data }),
  success: (payload) => action(PICKUP_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(PICKUP_NEWS[FAILURE], { payload }),
};

export const updateLatestVersion = {
  request: (id, data) => action(UPDATE_LATEST_VERSION[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_LATEST_VERSION[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_LATEST_VERSION[FAILURE], { payload }),
};

export const createNewVersion = {
  request: (id, data) => action(CREATE_NEW_VERSION[REQUEST], { id, data }),
  success: (payload) => action(CREATE_NEW_VERSION[SUCCESS], { payload }),
  failure: (payload) => action(CREATE_NEW_VERSION[FAILURE], { payload }),
};

export const getWorkflowHistory = {
  request: (id) => action(GET_WORKFLOW_HISTORY[REQUEST], { id }),
  success: (payload) => action(GET_WORKFLOW_HISTORY[SUCCESS], { payload }),
  failure: (payload) => action(GET_WORKFLOW_HISTORY[FAILURE], { payload }),
};

export const addGroupsToNews = {
  request: (id, data) => action(ADD_GROUPS_TO_NEWS[REQUEST], { id, data }),
  success: (payload) => action(ADD_GROUPS_TO_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(ADD_GROUPS_TO_NEWS[FAILURE], { payload }),
};

export const cancelPublication = {
  request: (id) => action(CANCEL_PUBLICATION[REQUEST], { id }),
  success: (payload) => action(CANCEL_PUBLICATION[SUCCESS], { payload }),
  failure: (payload) => action(CANCEL_PUBLICATION[FAILURE], { payload }),
};

export const setPublicationDate = {
  request: (id, data) => action(SET_PUBLICATION_DATE[REQUEST], { id, data }),
  success: (payload) => action(SET_PUBLICATION_DATE[SUCCESS], { payload }),
  failure: (payload) => action(SET_PUBLICATION_DATE[FAILURE], { payload }),
};

export const updateNewsPartially = {
  request: (id, data) => action(UPDATE_NEWS_PARTIALLY[REQUEST], { id, data }),
  success: (payload) => action(UPDATE_NEWS_PARTIALLY[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_NEWS_PARTIALLY[FAILURE], { payload }),
};

export const downloadPdf = {
  request: (data) => action(DOWNLOAD_PDF_NEWS[REQUEST], { data }),
  success: (payload) => action(DOWNLOAD_PDF_NEWS[SUCCESS], { payload }),
  failure: (payload) => action(DOWNLOAD_PDF_NEWS[FAILURE], { payload }),
};

export const selectNews = (data) => ({
  type: SELECT_NEWS,
  payload: data,
});

export const setNewsTableFilter = (payload) => ({
  type: SET_NEWS_TABLE_FILTER,
  payload,
});

export const setUserProductFilter = (payload) => ({
  type: SET_USER_PRODUCT_FILTER,
  payload,
});

export const setUserFeedFilter = (payload) => ({
  type: SET_USER_FEED_FILTER,
  payload,
});

export const setUserFeedSorting = (payload) => ({
  type: SET_USER_FEED_SORTING,
  payload,
});

export const setWorkflowTableFilter = (payload) => ({
  type: SET_WORKFLOW_TABLE_FILTER,
  payload,
});

export const setWorkflowTableSorting = (payload) => ({
  type: SET_WORKFLOW_TABLE_SORTING,
  payload,
});

export const setNewsTableSorting = (payload) => ({
  type: SET_NEWS_TABLE_SORTING,
  payload,
});

export const setNewsTab = (payload) => ({
  type: SET_NEWS_TAB,
  payload,
});
