import {takeEvery, put, select} from "redux-saga/effects";
import {apiRequest, secureApiRequest} from "./index";
import api from "utils/api";
import * as userActions from "actions/User";
import * as tagsActions from "actions/Tags";
import {Slide, toast} from "react-toastify";
import {get} from "lodash";
import history from "../utils/history";

const getTags = secureApiRequest.bind(null, tagsActions.getTags, api.getTags);
const getTag = secureApiRequest.bind(null, tagsActions.getTag, api.getTag);
const createTag = secureApiRequest.bind(null, tagsActions.createTag, api.createTag);
const deleteTag = secureApiRequest.bind(null, tagsActions.deleteTag, api.deleteTag);
const updateTag = secureApiRequest.bind(null, tagsActions.updateTag, api.updateTag);
const addTagToUser = secureApiRequest.bind(null, tagsActions.addTagToUser, api.addTagToUser);
const deleteTagFromUser = secureApiRequest.bind(null, tagsActions.deleteTagFromUser, api.deleteTagFromUser);

function* refreshTags() {
  yield put({type: tagsActions.GET_TAGS.REQUEST});
}

function* handleSuccess(action) {
  const arg = {id: action.payload.id};
  yield apiRequest.apply(null, [userActions.getUser, api.getUser, arg])

  yield toast('Gespeichert', {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: "bottom-center",
    type: 'success'
  })
}

function* handleCreateSuccess(action) {
  if (get(action.payload, 'options') === "redirect") {
    return yield history.push("/tags");
  } else {
    yield history.push(`/edit-tag/${action.payload.name}`)
    const arg = {id: action.payload.name}
    yield apiRequest.apply(null, [tagsActions.getTag, api.getTag, arg])

    yield toast('Gespeichert', {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type: 'success'
    })
  }
}

export function* watchGetTagRequest() {
  yield takeEvery(tagsActions.GET_TAG.REQUEST, getTag);
}

export function* watchGetTagsRequest() {
  yield takeEvery(tagsActions.GET_TAGS.REQUEST, getTags);
}

export function* watchCreateTagRequest() {
  yield takeEvery(tagsActions.CREATE_TAG.REQUEST, createTag);
}

export function* watchCreateTagSuccess() {
  yield takeEvery(tagsActions.CREATE_TAG.SUCCESS, handleCreateSuccess);
}

export function* watchUpdateTagSuccess() {
  yield takeEvery(tagsActions.UPDATE_TAG.SUCCESS, handleCreateSuccess);
}

export function* watchUpdateTagRequest() {
  yield takeEvery(tagsActions.UPDATE_TAG.REQUEST, updateTag);
}

export function* watchDeleteTagRequest() {
  yield takeEvery(tagsActions.DELETE_TAG.REQUEST, deleteTag);
}

export function* watchDeleteTagSuccess() {
  yield takeEvery(tagsActions.DELETE_TAG.SUCCESS, refreshTags);
}

export function* watchAddTagToUserRequest() {
  yield takeEvery(tagsActions.ADD_TAG_TO_USER.REQUEST, addTagToUser);
}

export function* watchDeleteTagFromUserRequest() {
  yield takeEvery(tagsActions.DELETE_TAG_FROM_USER.REQUEST, deleteTagFromUser);
}

export function* watchAddTagToUserSuccess() {
  yield takeEvery(tagsActions.ADD_TAG_TO_USER.SUCCESS, handleSuccess);
}

export function* watchDeleteTagFromUserSuccess() {
  yield takeEvery(tagsActions.DELETE_TAG_FROM_USER.SUCCESS, handleSuccess);
}

