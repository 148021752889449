import React from "react";
import { Container } from "reactstrap";
// import {
//   faInstagram,
//   faFacebookF
// } from '@fortawesome/free-brands-svg-icons';
//
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AppFooter extends React.Component {
  render() {
    return (
      <div className="app-footer">
        <div className="app-footer__inner">
          <Container>
            <div className="app-footer-left">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://iqest.com/data-protection"
              >
                Datenschutz |{" "}
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://iqest.com/impressum"
              >
                Impressum |{" "}
              </a>{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://iqest.com/cookies-policy"
              >
                Verwendung von Cookies
              </a>
            </div>
            <div className="app-footer-center">
              {/*
                          <span><FontAwesomeIcon icon={faInstagram} /></span>
                          <span><FontAwesomeIcon icon={faFacebookF} /></span>
                        */}
            </div>
            <div className="app-footer-right">
              Copyright 2021 IQ Digital Health GmbH
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default AppFooter;
