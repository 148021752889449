import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_CAMPAIGNS = createRequestTypes('GET_CAMPAIGNS');
export const GET_CAMPAIGN = createRequestTypes('GET_CAMPAIGN');
export const GET_CAMPAIGN_BY_VERSION = createRequestTypes('GET_CAMPAIGN_BY_VERSION');
export const START_CAMPAIGN = createRequestTypes('START_CAMPAIGN');
export const CREATE_CAMPAIGN = createRequestTypes('CREATE_CAMPAIGN');
export const UPDATE_CAMPAIGN = createRequestTypes('UPDATE_CAMPAIGN');
export const GET_USER_CAMPAIGNS = createRequestTypes('GET_USER_CAMPAIGNS');
export const GET_USER_ARCHIVED_CAMPAIGNS = createRequestTypes('GET_USER_ARCHIVED_CAMPAIGNS');
export const SET_CAMPAIGNS_TABLE_FILTER = 'SET_CAMPAIGNS_TABLE_FILTER';
export const SET_CAMPAIGNS_TABLE_SORTING = 'SET_CAMPAIGNS_TABLE_SORTING';

export const getCampaigns = {
  request: (meta) => action(GET_CAMPAIGNS[REQUEST], {meta}),
  success: (payload) => action(GET_CAMPAIGNS[SUCCESS], {payload}),
  failure: (payload) => action(GET_CAMPAIGNS[FAILURE], {payload})
}

export const getCampaign = {
  request: (id, meta) => action(GET_CAMPAIGN[REQUEST], {id, meta}),
  success: (payload) => action(GET_CAMPAIGN[SUCCESS], {payload}),
  failure: (payload) => action(GET_CAMPAIGN[FAILURE], {payload})
}

export const getCampaignByVersion = {
  request: (data) => action(GET_CAMPAIGN[REQUEST], {data}),
  success: (payload) => action(GET_CAMPAIGN[SUCCESS], {payload}),
  failure: (payload) => action(GET_CAMPAIGN[FAILURE], {payload})
}

export const createCampaign = {
  request: (data) => action(CREATE_CAMPAIGN[REQUEST], {data}),
  success: (payload) => action(CREATE_CAMPAIGN[SUCCESS], {payload}),
  failure: (payload) => action(CREATE_CAMPAIGN[FAILURE], {payload})
}

export const updateCampaign = {
  request: (data) => action(UPDATE_CAMPAIGN[REQUEST], {data}),
  success: (payload) => action(UPDATE_CAMPAIGN[SUCCESS], {payload}),
  failure: (payload) => action(UPDATE_CAMPAIGN[FAILURE], {payload})
}

export const startCampaign = {
  request: (data) => action(START_CAMPAIGN[REQUEST], {data}),
  success: (payload) => action(START_CAMPAIGN[SUCCESS], {payload}),
  failure: (payload) => action(START_CAMPAIGN[FAILURE], {payload})
}

export const getUserCampaigns = {
  request: (data) => action(GET_USER_CAMPAIGNS[REQUEST], {data}),
  success: (payload) => action(GET_USER_CAMPAIGNS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_CAMPAIGNS[FAILURE], {payload})
}

export const getUserArchivedCampaigns = {
  request: (data) => action(GET_USER_ARCHIVED_CAMPAIGNS[REQUEST], {data}),
  success: (payload) => action(GET_USER_ARCHIVED_CAMPAIGNS[SUCCESS], {payload}),
  failure: (payload) => action(GET_USER_ARCHIVED_CAMPAIGNS[FAILURE], {payload})
}

export const setCampaignsTableFilter = (payload) => ({
  type: SET_CAMPAIGNS_TABLE_FILTER,
  payload
})

export const setCampaignsTableSorting = (payload) => ({
  type: SET_CAMPAIGNS_TABLE_SORTING,
  payload
})